import { Tooltip } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import arrowUp from "../../svg/arrow-up.svg";
import infoCircle from "../../svg/info-circle.svg";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
import { setData } from "../../store/slices/errorMessageSlice";
import {
  useGetCompetitors,
  useGetGoldPrice,
  useUpdateGoldPrice,
} from "../../services/apis/liveGoldPrice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import greenArrow from "../../svg/arrow-up-green.svg";
import redArrow from "../../svg/arrow-down-red.svg";
import { useGlobalSocket } from "../../useGlobalSocket";
const LiveGoldPrice = () => {
  const dispatch = useDispatch();
  const {
    data,
    refetch,
    isLoading: goldPriceLoading,
    isFetching,
  } = useGetGoldPrice();
  const { mutateAsync } = useUpdateGoldPrice();
  const { data: goldCompetitors } = useGetCompetitors({ page: 0 });
  const [isDisabled, setIsDisabled] = useState(false);
  const [competitors, setCompetitors] = useState(null);
  const [goldPriceBuy, setGoldPriceBuy] = useState();
  const [goldPriceSell, setGoldPriceSell] = useState();
  const [goldPrice, setGoldPrice] = useState({
    buychange: data?.buyPer,
    sellchange: data?.sellPer,
  });
  const { addEventListener, removeEventListener } = useGlobalSocket();
  useEffect(() => {
    addEventListener("goldPrice-competitors", (data) => {
      setCompetitors(data?.competitors);
    });
    addEventListener("goldPrice-update-buy", (data) => {
      setGoldPriceBuy(data);
      setGoldPrice({ ...goldPrice, buychange: data?.buyPer });
    });
    addEventListener("goldPrice-update-sell", (data) => {
      setGoldPrice({ ...goldPrice, sellchange: data?.sellPer });
      setGoldPriceSell(data);
    });

    return () => {
      removeEventListener("goldPrice-competitors", () => {});
      removeEventListener("goldPrice-update-buy", () => {});
      removeEventListener("goldPrice-update-sell", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener]);
  useEffect(() => {
    if (!goldPriceLoading) {
      setGoldPrice({
        buychange: data?.buyPer,
        sellchange: data?.sellPer,
      });
    }
  }, [goldPriceLoading]);
  const LiveGoldPriceColumns = ItemsColumns([
    { headerName: "Competitor Name", field: "provider" },
    {
      headerName: "Buy Price (EGP) per Gram",
      field: "buy_gold_24",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.buyPercentage > 0 ? (
          <div className="flex items-center gap-2">
            <img src={greenArrow} alt="green" />{" "}
            {Number(params.row?.buy_gold_24)?.toLocaleString()}
          </div>
        ) : (
          <div className="flex items-center gap-2">
            {" "}
            <img src={redArrow} alt="red" />{" "}
            {Number(params.row?.buy_gold_24)?.toLocaleString()}
          </div>
        ),
    },
    {
      headerName: "Sell Price (EGP) per Gram",
      field: "sell_gold_24",
      specialRender: "customRender",
      renderFunction: (params) =>
        params?.row?.sellPercentage > 0 ? (
          <div className="flex items-center gap-2">
            <img src={greenArrow} alt="green" />{" "}
            {Number(params.row?.sell_gold_24)?.toLocaleString()}
          </div>
        ) : (
          <div className="flex items-center gap-2">
            {" "}
            <img src={redArrow} alt="red" />{" "}
            {Number(params.row?.sell_gold_24)?.toLocaleString()}
          </div>
        ),
    },
    { headerName: "Last Update", field: "date", date: true },
  ]);

  return (
    <div className="mx-6">
      <DashboardMainHeader />
      <Header title={"Live Gold Price"} />

      {/* Gold Price Cards */}
      <div className="grid md:grid-cols-2 items-center mt-8 gap-8">
        {/* Buy Price Card */}
        <div
          className="w-full rounded-[20px] bg-white"
          style={{
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
          }}
        >
          <div className="flex flex-col p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-[#595959] text-xs">Live Buy Gold (Sabika)</h1>
              <Tooltip title="Gold" placement="top" className="ml-auto">
                <img src={infoCircle} alt="info" className="cursor-pointer" />
              </Tooltip>
            </div>
            <div className="flex items-center justify-between mt-4">
              <p className="text-[#e9c237] text-2xl font-semibold">
                {goldPriceBuy
                  ? Number(goldPriceBuy?.buy)?.toLocaleString()
                  : Number(data?.buy_gold_24)?.toLocaleString()}{" "}
                <span className="text-[#595959] text-[0.625rem]">EGP</span>
              </p>
              <p className="bg-[#fbf5de] rounded-[4px] text-[#e9c237] text-[0.625rem] flex p-1">
                {goldPriceBuy
                  ? goldPriceBuy?.buyPercentage
                  : data?.buyPercentage}
                %
                <img
                  src={arrowUp}
                  alt="arrow up"
                  style={{
                    rotate:
                      (goldPriceBuy
                        ? goldPriceBuy?.buyPercentage
                        : data?.buyPercentage) > 0
                        ? "0deg"
                        : "180deg",
                  }}
                />
              </p>
            </div>
          </div>
        </div>

        {/* Sell Price Card */}
        <div
          className="w-full rounded-[20px] bg-white"
          style={{
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
          }}
        >
          <div className="flex flex-col p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-[#595959] text-xs">
                Live Sell Gold (Sabika)
              </h1>
              <Tooltip title="Gold" placement="top" className="ml-auto">
                <img src={infoCircle} alt="info" className="cursor-pointer" />
              </Tooltip>
            </div>
            <div className="flex items-center justify-between mt-4">
              <p className="text-[#e9c237] text-2xl font-semibold">
                {goldPriceSell
                  ? Number(goldPriceSell?.sell)?.toLocaleString()
                  : Number(data?.sell_gold_24)?.toLocaleString()}{" "}
                <span className="text-[#595959] text-[0.625rem]">EGP</span>
              </p>
              <p className="bg-[#fbf5de] rounded-[4px] text-[#e9c237] text-[0.625rem] flex p-1">
                {goldPriceSell
                  ? goldPriceSell?.sellPercentage
                  : data?.sellPercentage}
                %
                <img
                  src={arrowUp}
                  alt="arrow up"
                  style={{
                    rotate:
                      (goldPriceSell
                        ? goldPriceSell?.sellPercentage
                        : data?.sellPercentage) > 0
                        ? "0deg"
                        : "180deg",
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Update Prices Form */}
      <div
        className="mt-4 mb-8 p-6 rounded-[0.625rem] bg-white"
        style={{
          boxShadow:
            "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
        }}
      >
        <p className="text-[#595959] mb-8 text-lg">Update Prices</p>
        <div className="grid md:grid-cols-2 items-center gap-8">
          {/* Buy Price Input */}
          <div className="w-full">
            <p className="mb-3">New Buy Price</p>
            <input
              min={-15}
              max={15}
              value={goldPrice.buychange}
              onChange={(e) => {
                let value = e.target.value;

                // Allow an empty input or just a minus sign
                if (value === "" || value === "-") {
                  setGoldPrice((prev) => ({ ...prev, buychange: value }));
                  return;
                }

                // Check if the input is a valid number within range with up to two decimals
                if (/^-?(15|[0-9]{1,2})(\.\d{0,2})?$/.test(value)) {
                  const parsedValue = parseFloat(value);

                  // Ensure parsed value falls within the allowed range
                  if (parsedValue >= -15 && parsedValue <= 15) {
                    setGoldPrice((prev) => ({
                      ...prev,
                      buychange: value, // Keep value as string to allow precise input control
                    }));
                  }
                }
              }}
              className="w-full p-3 rounded-xl border border-[#e6e6e6] placeholder-[#e6e6e6]"
              placeholder="Enter New Buy Price"
              type="text" // Use 'text' to handle custom decimal validation and initial '-' sign
              style={{
                boxShadow:
                  "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
              }}
            />
          </div>

          {/* Sell Price Input */}
          <div className="w-full">
            <p className="mb-3">New Sell Price</p>
            <input
              min={-15}
              max={15}
              value={goldPrice.sellchange}
              onChange={(e) => {
                let value = e.target.value;

                // Allow an empty input or just a minus sign
                if (value === "" || value === "-") {
                  setGoldPrice((prev) => ({ ...prev, sellchange: value }));
                  return;
                }

                // Check if the input is a valid number within range with up to two decimals
                if (/^-?(15|[0-9]{1,2})(\.\d{0,2})?$/.test(value)) {
                  const parsedValue = parseFloat(value);

                  // Ensure parsed value falls within the allowed range
                  if (parsedValue >= -15 && parsedValue <= 15) {
                    setGoldPrice((prev) => ({
                      ...prev,
                      sellchange: value, // Keep value as string to allow precise input control
                    }));
                  }
                }
              }}
              className="w-full p-3 rounded-xl border border-[#e6e6e6] placeholder-[#e6e6e6]"
              placeholder="Enter New Buy Price"
              type="text" // Use 'text' to handle custom decimal validation and initial '-' sign
              style={{
                boxShadow:
                  "0px 1px 3px rgba(0, 0, 0, 0.02), 0px 1px 6px rgba(0, 0, 0, 0.07)",
              }}
            />
          </div>
        </div>

        {/* Update Button */}
        <button
          disabled={isFetching || isDisabled}
          onClick={async () => {
            setIsDisabled(true); // Disable the button
            setTimeout(() => setIsDisabled(false), 5000); // Enable it after 5 seconds
            // Uncomment for mutation
            await mutateAsync(goldPrice)
              .then(() => {
                refetch();
                dispatch(
                  setData({
                    severity: "success",
                    openSnack: true,
                    message: "Prices updated successfully!",
                  })
                );
              })
              .catch((error) => {
                dispatch(
                  setData({
                    severity: "error",
                    openSnack: true,
                    message: error.response.data.message,
                  })
                );
              });
          }}
          className="text-white bg-[#e9c237] font-medium px-12 py-[0.906rem] rounded-xl mt-8 md:ml-auto flex disabled:opacity-50"
        >
          Update Price
        </button>
      </div>

      <NewTable
        className="mb-10"
        columns={LiveGoldPriceColumns}
        isLoading={goldPriceLoading}
        data={competitors ? competitors : goldCompetitors}
        noPagination
      />
      <ReusableSnackbar />
    </div>
  );
};

export default LiveGoldPrice;

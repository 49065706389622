import { Box } from "@mui/material";
import { DataGridStyle } from "../../../styles";
import { useState } from "react";
import { usePendingOrders } from "../../../services/apis/orders";
import RequestOrderDialog from "./RequestOrderDialog";
import { ItemsColumns } from "../../../components/common/Columns";
import NewTable from "../../../components/common/NewTable";

const FirstPendingTabPanel = () => {
  const [open, setOpen] = useState(false);
  const { data, isFetching, refetch } = usePendingOrders();
  const [selectedRow, setSelectedRow] = useState(null); // State to keep track of the selected row

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null); // Clear selected row when closing the dialog
  };
  const allColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    {
      field: "total_weight",
      headerName: "Amount",
      specialRender: "customRender",
      renderFunction: (params) => {
        return parseFloat(params.row.total_weight);
      },
    },
    { field: "created", date: true },
    {
      field: "name",
      headerName: "Actions",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <Box>
            <button
              onClick={() => handleOpenDialog(params.row)}
              className="bg-[#e9c237] w-full p-2 text-white  rounded-2xl"
            >
              Request
            </button>
          </Box>
        );
      },
    },
  ]);
  const handleOpenDialog = (row) => {
    setSelectedRow(row); // Set the selected row data
    setOpen(true);
  };
  return (
    <Box>
      <Box
        sx={{
          height: "auto",
          background: "#FFF",
          borderRadius: "12px",
          pb: 2,
        }}
      >
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <NewTable
            noPagination
            columns={allColumns}
            isLoading={isFetching}
            data={data}
          />
        </Box>
      </Box>
      <RequestOrderDialog
        selectedRequest={selectedRow}
        fetchData={() => {
          refetch();
        }}
        handleResponse={() => {
          refetch();
        }}
        open={open}
        onClose={handleClose}
        label={"Request"}
      />
    </Box>
  );
};
export default FirstPendingTabPanel;

import React, { useEffect } from "react";
import { Typography, Box, Button, Divider, Skeleton } from "@mui/material";
import {
  approveUser,
  unBlockUser,
  useNewBlock,
  useUserDetails,
} from "../../services/apis/users";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { imageBaseUrl } from "../../services/config";
import KycDialog from "./KycDialog";
import Header from "../../components/Header";
import UserInfoField from "./UserInfoField";
import { makeStyles } from "@mui/styles";
import KycCard from "./KycCard";
import ActionAlert from "./ActionAlert";
import BlockUserDialog from "./BlockUserDialog";
import CustomDialog from "../../components/CustomDialog";
import dayjs from "dayjs";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useGlobalSocket } from "../../useGlobalSocket";
const useStyles = makeStyles({
  root: {
    textTransform: "none",
  },
});

const UserProfile = () => {
  const [disableWhileDeleting, setDisableWhileDeleting] = useState(false);
  const { id } = useParams();

  const { data, isLoading, refetch } = useUserDetails(id);

  const { addEventListener } = useGlobalSocket();

  // Example of adding a custom event listener
  useEffect(() => {
    addEventListener("users-update", ({ user_id }) => {
      // eslint-disable-next-line eqeqeq
      if (user_id == id) {
        refetch();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener]);

  const block = "";
  const [formData, setFormData] = useState({
    reject_kyc_reason: "",
  });
  const [approveButton, setApproveButton] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [rejectButton, setRejectButton] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openBlockedAlert, setOpenBlockedAlert] = useState(false);
  const [type, setType] = useState("");
  const [userGroup, setUserGroup] = useState(null);
  const classes = useStyles();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const { mutateAsync } = useNewBlock();
  const dispatch = useDispatch();

  const handleApprove = async (nid) => {
    try {
      await approveUser("approved", id, nid);
      refetch();
      setApproveButton(false);
      setOpenDialog(false);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message:
            error?.response?.data?.validation?.nationalId ||
            error?.response?.data?.message,
        })
      );
    }
  };

  const handleReject = async () => {
    setDisableWhileDeleting(true);
    try {
      await approveUser("rejected", id, {
        kyc_rejection_reason_id: formData.reject_kyc_reason,
      });
      setFormData({ reject_kyc_reason: "" });
      setDisableWhileDeleting(false);
      refetch();
      setRejectButton(false);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.response?.data?.message,
        })
      );
    }
    setOpenAlert(false);
  };

  const handleBlock = async (data) => {
    const parsedDate = dayjs(data?.dateTo, "MM/DD/YYYY");
    await mutateAsync({
      id: Number(id),
      block_reason_id: data?.selectedReason,
      unBlock_at: data?.dateTo
        ? parsedDate.format("YYYY-MM-DDTHH:mm:ss")
        : null,
    });
    refetch();
    setOpenBlockedAlert(false);
  };
  const handleUnBlock = async () => {
    try {
      await unBlockUser(id);
      refetch();
      setSuccessDialogOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.data?.data?.message,
        })
      );
    }
    setOpenBlockedAlert(false);
  };

  const checkUserStatus = (user) => {
    if (user.kyc_status.toLowerCase() === "pending") {
      setApproveButton(true);
      setRejectButton(true);
    }

    if (user.kyc_status.toLowerCase() === "approved") {
      setApproveButton(false);
      setRejectButton(true);
    }

    if (user.kyc_status.toLowerCase() === "rejected") {
      setApproveButton(true);
      setRejectButton(false);
    }
  };

  useEffect(() => {
    if (data?.data) {
      checkUserStatus(data?.data);
      setUserGroup(data?.data?.group);
    }
  }, [data?.data]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: 2,
        background: "#F0F0F0",
        alignItems: "center",
        paddingBottom: "2%",
      }}
    >
      <div style={{ width: "100%" }}>
        <DashboardMainHeader />
      </div>
      <Header
        title="Personal Information"
        marginT={"1%"}
        marginL={3}
        sx={{ alignSelf: "start" }}
      />
      <div className="flex w-[95%]">
        <div className="flex flex-col w-full gap-3">
          <div className="flex w-full justify-between">
            <UserInfoField
              title="Full Name"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  data?.data?.full_name
                )
              }
            />
            <a
              href={`/transactions/${id}`}
              className="text-[#00303c] text-xs font-semibold underline"
            >
              See All user Transactions
            </a>
          </div>
          <Divider />
          <div className="flex w-full justify-between">
            <UserInfoField
              title="National ID Name"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  data?.data?.national_name
                )
              }
            />
          </div>
          <Divider />
          <div className="flex w-full justify-between">
            <UserInfoField
              title="National ID"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  data?.data?.nationalId
                )
              }
            />
          </div>
          <Divider />
          <div className="flex w-full justify-between">
            <UserInfoField
              title="KYC Status"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  data?.data?.kyc_status
                )
              }
            />
          </div>
          {data?.data?.kyc_status === "rejected" ? (
            <>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <UserInfoField
                  title="KYC Rejection reason"
                  data={
                    isLoading ? (
                      <Skeleton sx={{ width: 200 }} />
                    ) : (
                      data?.data?.reject_kyc_reason
                    )
                  }
                />
              </Box>
            </>
          ) : null}
          <Divider />
          {data?.data?.deleted_at === null && (
            <>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <UserInfoField
                  title="User Status"
                  data={
                    isLoading ? (
                      <Skeleton sx={{ width: 200 }} />
                    ) : userGroup === "blockedUser" ? (
                      "Blocked"
                    ) : (
                      "Active"
                    )
                  }
                />
              </Box>
              <Divider />
            </>
          )}

          <div className="flex w-full justify-between">
            <UserInfoField
              title="Email"
              data={
                isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.data?.email
              }
            />
          </div>
          <Divider />

          <div className="flex w-full justify-between">
            <UserInfoField
              title="Mobile"
              data={
                isLoading ? <Skeleton sx={{ width: 200 }} /> : data?.data?.phone
              }
            />
          </div>
          <Divider />

          <div className="flex w-full justify-between">
            <UserInfoField
              title="Wallet Balance"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : data?.data?.currentBalance ? (
                  parseFloat(data?.data.currentBalance).toLocaleString()
                ) : (
                  ""
                )
              }
            />
          </div>
          <Divider />

          {data?.data?.deleted_at === null ? (
            <Button
              variant="contained"
              sx={{
                "&:hover": { backgroundColor: "#F23045" },
                color: "#FFF",
                width: "150px",
                background: "#F23045",
                height: "37px",
                borderRadius: "9px",
                alignSelf: "flex-end",
              }}
              className={classes.root}
              onClick={() => {
                setOpenBlockedAlert(true);
              }}
            >
              {userGroup === "blockedUser" ? "Unblock" : "Block"}
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                "&:hover": { backgroundColor: "#E9C237" },
                color: "#FFF",
                width: "150px",
                background: "#E9C237",
                height: "37px",
                borderRadius: "9px",
                alignSelf: "flex-end",
              }}
              className={classes.root}
              // onClick={handleOpenBlockAlert}
            >
              Restore Account
            </Button>
          )}
        </div>
      </div>
      <div className="flex bg-white p-8 shadow-lg w-[85%] rounded-[9px]">
        <div className="flex flex-col w-full gap-2">
          <Typography
            variant="h3"
            className="text-[#333] font-lato text-lg font-semibold ml-4"
          >
            KYC
          </Typography>

          <div className="flex  flex-col gap-4  md:flex-row   w-full justify-center md:gap-2">
            <KycCard
              isLoading={isLoading}
              title="Personal Picture"
              img={
                data?.data?.photo_verification
                  ? `${imageBaseUrl}/${data?.data?.photo_verification}`
                  : `../../assets/noKyc.png`
              }
            />
            <KycCard
              isLoading={isLoading}
              title="Front ID attachment"
              img={
                data?.data?.national_front
                  ? `${imageBaseUrl}/${data?.data?.national_front}`
                  : `../../assets/noKyc.png`
              }
            />
            <KycCard
              isLoading={isLoading}
              title="Back ID attachment"
              img={
                data?.data?.national_back
                  ? `${imageBaseUrl}/${data?.data?.national_back}`
                  : `../../assets/noKyc.png`
              }
            />
          </div>
          {data?.data?.deleted_at === null && (
            <div
              className={`
            flex justify-end whitespace-nowrap mt-2
            ${
              approveButton && rejectButton
                ? "justify-between md:justify-end md:gap-4"
                : ""
            }
            `}
            >
              {approveButton && (
                <Button
                  className={classes.root}
                  variant="contained"
                  sx={{
                    // display: "flex",
                    // width: "150px",
                    borderRadius: "6px",
                    background: "#E9C237",
                    // alignSelf: "end"
                    "&:hover": {
                      background: "#E9C237",
                    },
                  }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                  id="Approve"
                >
                  Approve KYC{" "}
                </Button>
              )}

              {rejectButton && (
                <Button
                  className={classes.root}
                  variant="contained"
                  sx={{
                    "&:hover": { backgroundColor: "#F23045" },
                    // display: "flex",
                    // width: "150px",
                    borderRadius: "6px",
                    background: "#F23045",
                    // alignSelf: "end"
                  }}
                  onClick={(e) => {
                    setType(e.target.id);
                    setOpenAlert(true);
                  }}
                  id="Reject"
                >
                  Reject KYC
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <KycDialog
        isLoading={isLoading}
        openDialog={openDialog}
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        handleApprove={handleApprove}
        image={
          data?.data?.national_front
            ? `${imageBaseUrl}/${data?.data?.national_front}`
            : `../../assets/noKyc.png`
        }
      />
      <ActionAlert
        disabled={disableWhileDeleting || !formData?.reject_kyc_reason}
        setFormData={setFormData}
        formData={formData}
        openAlert={openAlert}
        handleCloseAlert={() => {
          setOpenAlert(false);
        }}
        type={type}
        handleReject={handleReject}
        message={`Are you sure you want to ${
          type === "Approve" ? "Approve" : "Reject"
        } KYC?`}
        label={type === "Approve" ? "Approve" : "Reject"}
        alertType={"KYC"}
      />
      {/* block user action alert  */}
      <BlockUserDialog
        openAlert={openBlockedAlert}
        handleCloseAlert={() => {
          setOpenBlockedAlert(false);
        }}
        type={block}
        userGroup={userGroup}
        handleReject={userGroup === "blockedUser" ? handleUnBlock : handleBlock}
        message={`Are you sure you want to ${
          userGroup === "blockedUser" ? "Unblock" : "Block"
        } this user`}
        label={userGroup === "blockedUser" ? "Unblock" : "Block"}
        alertType={"Block"}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={`User has been ${
          userGroup === "blockedUser" ? "Blocked" : "UnBlocked"
        } Successfully`}
        message=""
        buttonText="Users Information"
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default UserProfile;

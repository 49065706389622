import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputBase, Paper, IconButton } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Popover from "@mui/material/Popover";
import { useGoldTransactions } from "../../services/apis/transactions";
import Header from "../../components/Header";
import { DataGridStyle } from "../../styles";
import { handlePageChange, handleSort } from "../../services/utils/filters";
import FilterDrawer from "../../components/common/FilterDrawer";
import { handleFilterByNumber } from "../../services/utils/filters";
import { TabContext, TabPanel } from "@mui/lab";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import GoldTransactionModal from "./GoldTransactionModal";
import FractionTransactionModal from "./FractionTransactionModal";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import NewTabs from "../../components/common/NewTabs";
import { CustomLoadingOverlay } from "../../components/common/CustomLoadingOverlay";
import NewTable from "../../components/common/NewTable";

const GoldTransactions = () => {
  const [value, setValue] = useState("1");

  const tabs = [
    {
      label: "Gold Ingots Transactions",
      value: "1",
    },
    { label: "Fractions transactions", value: "2" },
  ];
  const [goldTransactionsDetails, setGoldTransactionsDetails] = useState([]);
  const [fractionsTransactionsDetails, setFractionsTransactionsDetails] =
    useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverValue, setPopoverValue] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFractionDialog, setOpenFractionDialog] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    status: "",
    goldType: [1, 2, 4],
    goldTransactionType: [1, 2],
    query: null,
  });
  const { data: goldData, isFetching: goldLoading } =
    useGoldTransactions(queryParameters);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === "1") {
      setQueryParameters({
        ...queryParameters,
        date_from: null,
        date_to: null,
        orderBy: null,
        sort: null,
        page: 0,
        status: "",
        goldType: [1, 2, 4],
        goldTransactionType: [1, 2],
        query: null,
      });
    } else if (newValue === "2") {
      setQueryParameters({
        ...queryParameters,
        date_from: null,
        date_to: null,
        orderBy: null,
        sort: null,
        page: 0,
        status: "",
        goldType: [3],
        goldTransactionType: [1, 2],
        query: null,
      });
    }
  };
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const getRowId = (row) => row.goldTransactionId ?? row.id;

  const handleCellClick = (params, event) => {
    const { row, field } = params;
    if (field === "username") navigate(`/users/${row.user.id}`);
    if (field === "totalWeight") {
      setAnchorEl(event.currentTarget);
      setPopoverValue(params.formattedValue);
    }
  };

  const handleSearchInput = (e) => {
    const { value } = e.target;
    setQueryParameters((prevParams) => ({
      ...prevParams,
      query: value.trim(),
    }));
  };

  const handleGoldBarsCheckox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "goldBarIds",
      queryParameters,
      setQueryParameters
    );
  };

  const handleGoldTypeCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "goldType",
      queryParameters,
      setQueryParameters
    );
  };
  const handleOpenDialog = (params) => {
    setGoldTransactionsDetails(params?.row);
    setOpenDialog(true);
  };
  const handleOpenFractionDialog = (params) => {
    setFractionsTransactionsDetails(params?.row);
    setOpenFractionDialog(true);
  };
  const handleCloseDialog = () => setOpenDialog(false);
  const handleCloseFractionDialog = () => setOpenFractionDialog(false);
  const newGoldTransactionsColumns = ItemsColumns([
    { field: "user_phone", headerName: "User Phone No." },
    { field: "created", headerName: "Date", date: true },
    {
      field: "amount",
      headerName: "Ingot Amount",
      specialRender: "abs",
    },
    { field: "goldBarName", headerName: "Product Name" },
    {
      field: "total_weight",
      headerName: "Total Weight",
      specialRender: "totalWeight",
    },
    { field: "price", headerName: "Gold Price", specialRender: "absLocal" },
    { field: "total", headerName: "Total Price", specialRender: "absLocal" },
    {
      field: "goldTransactionType",
      headerName: "Type",
      specialRender: "goldTransactionType",
    },
    {
      field: "serials",
      headerName: "Serial No.",
      specialRender: "serials",
      serialsFunction: (params) => handleOpenDialog(params),
    },
  ]);
  const newFractionTransactionsColumns = ItemsColumns([
    { field: "user_phone", headerName: "User Phone No.", flex: 2 },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    {
      field: "total_weight",
      headerName: "Weight",
      specialRender: "totalWeight",
    },
    { field: "price", headerName: "Gold Price", specialRender: "absLocal" },
    { field: "total", headerName: "Total Price", specialRender: "absLocal" },
    {
      field: "goldTransactionType",
      headerName: "Type",
      specialRender: "goldTransactionType",
    },
    {
      field: "serials",
      headerName: "Serial No.",
      specialRender: "serials",
      serialsFunction: (params) => handleOpenFractionDialog(params),
    },
  ]);

  return (
    <Box>
      <Box sx={{ ml: "20px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Gold Transactions" marginL={"20px"} />
      <div className="bg-white m-4 rounded-xl">
        <TabContext value={value}>
          <NewTabs onChange={handleChange} tabs={tabs} value={value} />
          <TabPanel sx={{}} value="1">
            {/* <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 w-[95%] md:w-3/5 mb-4">
              <IconButton type="button" aria-label="search">
                <img
                  alt="search-normal"
                  src={`../../assets/search-normal.png`}
                />
              </IconButton>
              <InputBase
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Email or Phone number....."
              />
              <FilterDrawer
                queryParameters={queryParameters}
                setQueryParameters={setQueryParameters}
                handleFirstCheckBox={handleGoldBarsCheckox}
                handleSecondCheckBox={handleGoldTypeCheckbox}
                isGold={true}
              />
            </div> */}
            <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 mb-4 w-[95%] md:w-3/5">
              <IconButton type="button" aria-label="search">
                <img
                  alt="search-normal"
                  src={`../../assets/search-normal.png`}
                />
              </IconButton>
              <InputBase
                onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Email or Phone number....."
              />
              <FilterDrawer
                queryParameters={queryParameters}
                setQueryParameters={setQueryParameters}
                handleFirstCheckBox={handleGoldBarsCheckox}
                handleSecondCheckBox={handleGoldTypeCheckbox}
                isGold={true}
              />
            </div>
            <NewTable
              columns={newGoldTransactionsColumns}
              isLoading={goldLoading}
              data={goldData?.data}
              totalItems={goldData?.totalItems}
              totalPages={goldData?.totalPages}
              handlePageChange={(newPage) =>
                handlePageChange(newPage, queryParameters, setQueryParameters)
              }
              onCellClick={handleCellClick}
              currentPage={queryParameters}
            />
          </TabPanel>
          <TabPanel sx={{}} value="2">
            <Box
              sx={{
                height: "auto",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {/* <Box sx={{ display: "flex", width: "100%", gap: 2, mb: 2 }}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      width: "60%",
                      height: "48px",
                      backgroundColor: "#F5F5F5",
                      boxShadow: "none",
                      borderRadius: "12px",
                    }}
                    onSubmit={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <InputBase
                      onChange={handleSearchInput}
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search National Id ,Phone number or Email ....."
                    />
                    <FilterDrawer
                      queryParameters={queryParameters}
                      setQueryParameters={setQueryParameters}
                      handleFirstCheckBox={handleGoldBarsCheckox}
                      handleSecondCheckBox={handleGoldTypeCheckbox}
                      isGold={true}
                    />
                  </Paper>
                </Box> */}

              <div className="flex items-center rounded-xl bg-[#F5F5F5] p-2 w-[95%] md:w-3/5 mb-4">
                <IconButton type="button" aria-label="search">
                  <img
                    alt="search-normal"
                    src={`../../assets/search-normal.png`}
                  />
                </IconButton>
                <InputBase
                  onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Email or Phone number....."
                />
                <FilterDrawer
                  queryParameters={queryParameters}
                  setQueryParameters={setQueryParameters}
                  handleFirstCheckBox={handleGoldBarsCheckox}
                  handleSecondCheckBox={handleGoldTypeCheckbox}
                  isGold={true}
                />
              </div>
              <NewTable
                columns={newFractionTransactionsColumns}
                isLoading={goldLoading}
                data={goldData?.data}
                totalItems={goldData?.totalItems}
                totalPages={goldData?.totalPages}
                handlePageChange={(newPage) =>
                  handlePageChange(newPage, queryParameters, setQueryParameters)
                }
                onCellClick={handleCellClick}
                currentPage={queryParameters}
              />
            </Box>
          </TabPanel>
        </TabContext>
      </div>
      <SecondBox>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography sx={{ p: 2 }}>{popoverValue}</Typography>
        </Popover>
      </SecondBox>
      <GoldTransactionModal
        open={openDialog}
        onClose={handleCloseDialog}
        data={goldTransactionsDetails}
      />
      <FractionTransactionModal
        open={openFractionDialog}
        onClose={handleCloseFractionDialog}
        data={fractionsTransactionsDetails}
      />
    </Box>
  );
};

export default GoldTransactions;

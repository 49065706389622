import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { fetchFaqs, deleteQuestion } from "../../services/apis/faq";
import { DataGridStyle } from "../../styles";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../user-profile/ActionAlert";
import CustomDialog from "../../components/CustomDialog";
import EditFaq from "./EditFaq";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";

const FAQ = () => {
  const [disableWhileDelete, setDisableWhileDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [selectedFaqId, setSelectedFaqId] = useState(null);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const getFaqs = async () => {
    setLoading(true);
    setFaqs([]);
    try {
      const response = await fetchFaqs();
      setFaqs(response.data.data.result);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddFAQ = () => {
    navigate(`/AddNewFAQ`);
  };
  const handleOpenDeleteAlert = (id) => {
    setSelectedFaqId(id);
    setOpenDeletedAlert(true);
  };
  const handleEditFaq = (faqId) => {
    setSelectedFaq(faqId);
    setOpen(true);
  };
  const handleDeleteFaq = async () => {
    if (selectedFaqId) {
      setDisableWhileDelete(true);
      try {
        await deleteQuestion(selectedFaqId);
        setDisableWhileDelete(false);
        setSuccessDialogOpen(true);
        handleCloseDeleteAlert();
        getFaqs();
      } catch (error) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      } finally {
        setDisableWhileDelete(false);
      }
    }
  };
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const FaqColumns = ItemsColumns([
    { field: "id", headerName: "Order" },
    {
      field: "transTitle",
      headerName: "Question",
      specialRender: "customRender",
      flex: 2,
      renderFunction: (params) => params?.row?.trans?.en?.title,
    },
    {
      field: "TransDesc",
      headerName: "Answer",
      specialRender: "customRender",
      flex: 2,
      renderFunction: (params) => {
        return <p>{params?.row?.trans?.en?.description}</p>;
      },
    },
    {
      field: "action",
      headerName: "Actions",

      actions: {
        edit: (params) => handleEditFaq(params.row.id),
        delete: (params) => handleOpenDeleteAlert(params.row.id),
      },
    },
  ]);

  useEffect(() => {
    getFaqs();
  }, []);
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <DashboardMainHeader />

      <Header title="FAQ" marginL={"25px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New FAQ"}
          onClick={() => {
            handleAddFAQ();
          }}
        />
      </Box>

      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <NewTable
            columns={FaqColumns}
            isLoading={loading}
            data={faqs}
            noPagination
          />
        </Box>
      </SecondBox>
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteFaq}
        message={`Are you sure you want to Delete this FAQ ?`}
        label={"Delete"}
        disabled={disableWhileDelete}
      />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title="FAQ has been deleted Successfully."
        message=""
        buttonText="Go to FAQ"
      />
      <EditFaq
        fetchData={fetchFaqs}
        setFaqs={setFaqs}
        faqs={faqs}
        faqId={selectedFaq}
        open={open}
        onClose={handleClose}
        label={"Save"}
      />
      <ReusableSnackbar />
    </Box>
  );
};

export default FAQ;

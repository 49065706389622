import { useState } from "react";
import TextFieldComponent from "../../components/TextFieldComponent";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import CustomTextArea from "../../components/CustomTextArea";
import { FaMinus } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import { useFetchSubjects } from "../../services/apis/subejct";
import { MenuProps } from "../../components/common/MenuProps";
import LoadingSpinner from "../../components/LoadingSpinner";
const StepOneTicketQuestions = ({
  setFaqData,
  faqData,
  type,
  handleTypeChange,
  subQuestions,
  handleAddSubQuestion,
  handleDeleteSubQuestion,
  subjectId,
  selectedTicketData,
  setIsValid,
}) => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const { data, isLoading } = useFetchSubjects();
  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      contactUs_type_id: event.target.value.id,
    }));
  };

  const handleSortChange = (event) => {
    const newValue = event.target.value.slice(0, 6);
    const sortValue = parseInt(newValue);
    setFaqData((prevPayload) => ({
      ...prevPayload,
      sort: isNaN(sortValue) ? null : sortValue,
    }));
  };

  const handleSubQuestionChange = (index, field, fieldType, value) => {
    setFaqData((prevFaqData) => ({
      ...prevFaqData,
      subQuestions: prevFaqData.subQuestions.map((subQuestion, i) => {
        if (i === index) {
          return {
            ...subQuestion,
            trans: subQuestion.trans.map((translation) => {
              if (translation.local === field) {
                return {
                  ...translation,
                  [fieldType]: value,
                };
              }
              return translation;
            }),
          };
        }
        return subQuestion;
      }),
    }));
  };
  useEffect(() => {
    const result = faqData?.subQuestions.map((item) => {
      if (item?.trans[0]?.question !== "" && item?.trans[0]?.answer !== "") {
        return true;
      } else {
        return false;
      }
    });
    if (
      selectedTopic &&
      faqData?.sort !== null &&
      faqData?.trans[0]?.question !== ""
    ) {
      if (type === "No" && faqData?.trans[0]?.answer) {
        setIsValid([true, false, false]);
      } else if (
        type === "Yes" &&
        !faqData?.trans[0]?.answer &&
        faqData?.subQuestions?.length > 0 &&
        !result.includes(false)
      ) {
        setIsValid([true, false, false]);
      } else {
        setIsValid([false, false, false]);
      }
    } else {
      setIsValid([false, false, false]);
    }
  }, [selectedTopic, faqData, subQuestions, type, setIsValid]);
  useEffect(() => {
    if (setFaqData) {
      setFaqData((prevData) => {
        let updatedData = {
          ...prevData,
          trans: prevData.trans.map((item) => ({
            ...item,
            question:
              item.local === "en"
                ? faqData.trans.find((t) => t.local === "en").question
                : item.question,
            answer:
              item.local === "en" && type === "No"
                ? faqData.trans.find((t) => t.local === "en").answer
                : item.answer,
          })),
          sort: faqData.sort,
          contactUs_type_id: faqData.contactUs_type_id,
        };
        if (updatedData.trans) {
          updatedData.trans = updatedData.trans.map(({ answer, ...rest }) =>
            type === "Yes" ? rest : { ...rest, answer }
          );
        }
        return updatedData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopic, setFaqData, subQuestions, type]);

  useEffect(() => {
    const initialSelectedSubject = data?.find(
      (subject) => subject?.id === faqData?.contactUs_type_id
    );
    setSelectedTopic(initialSelectedSubject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Box sx={{ marginTop: "3%" }}>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#4C4C4C",
            marginBottom: "5px",
            marginTop: "15px",
          }}
        >
          Select Subject
        </Typography>
        <FormControl sx={{ width: "100%" }}>
          <Select
            displayEmpty
            onChange={handleTopicChange}
            value={selectedTopic || ""}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return <em>Select Subject</em>;
              }
              return selected?.name_en;
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {data?.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.name_en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextFieldComponent
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#FFF",
          borderRadius: "9px",
        }}
        type="number"
        name="Enter Order"
        placeholder="Enter Order"
        value={faqData.sort}
        onChange={handleSortChange}
        maxLength={6}
      />
      <TextFieldComponent
        sx={{ "& fieldset": { border: "none" } }}
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "#F7F7F7",
          borderRadius: "9px",
        }}
        name="Question"
        placeholder="Enter Question"
        value={faqData.trans.find((t) => t.local === "en").question}
        onChange={(event) => {
          const allowedCharacters = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/; // Include English letters, numbers, and specified special characters.
          const inputValue = event.target.value;

          if (allowedCharacters.test(inputValue)) {
            const updatedPayload = { ...faqData };
            const enTranslation = updatedPayload.trans.find(
              (t) => t.local === "en"
            );
            if (enTranslation) {
              enTranslation.question = inputValue;
              setFaqData(updatedPayload);
            }
          }
        }}
      />

      {type === "No" && !subjectId && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Answer
          </Typography>
          <CustomTextArea
            style={{
              minHeight: "80px",
              maxHeight: "200px",
              maxWidth: "100%",
              minWidth: "100%",
              backgroundColor: "#FFF",
              borderRadius: "9px",
              boxShadow: "none",
              border: "1px solid #E9C237",
            }}
            name="Answer"
            placeholder="Enter Answer"
            value={faqData.trans.find((t) => t.local === "en").answer}
            onChange={(event) => {
              const allowedCharacters = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
              const inputValue = event.target.value;

              // Check if the input matches the allowed pattern
              if (allowedCharacters.test(inputValue)) {
                const updatedPayload = { ...faqData };
                const enTranslation = updatedPayload.trans.find(
                  (t) => t.local === "en"
                );
                if (enTranslation) {
                  enTranslation.answer = inputValue;
                  setFaqData(updatedPayload);
                }
              }
            }}
          />
        </Box>
      )}
      {selectedTicketData?.subQuestionsCount === 0 && subjectId && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
              marginBottom: "5px",
              marginTop: "15px",
            }}
          >
            Answer
          </Typography>
          <CustomTextArea
            style={{
              minHeight: "80px",
              maxHeight: "200px",
              maxWidth: "100%",
              minWidth: "100%",
              backgroundColor: "#FFF",
              borderRadius: "9px",
              boxShadow: "none",
              border: "1px solid #E9C237",
            }}
            name="Answer"
            placeholder="Enter Answer"
            value={faqData.trans.find((t) => t.local === "en").answer}
            onChange={(event) => {
              const allowedCharacters =
                /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
              const inputValue = event.target.value;

              // Check if the input matches the allowed pattern
              if (allowedCharacters.test(inputValue)) {
                const updatedPayload = { ...faqData };
                const enTranslation = updatedPayload.trans.find(
                  (t) => t.local === "en"
                );
                if (enTranslation) {
                  enTranslation.answer = inputValue;
                  setFaqData(updatedPayload);
                }
              }
            }}
          />
        </Box>
      )}
      {!subjectId && (
        <Box
          sx={{
            marginTop: "15px",
            width: "100%",
          }}
        >
          <FormLabel
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#4C4C4C",
            }}
            id="demo-row-radio-buttons-group-label"
          >
            Have Sub Questions
          </FormLabel>
          <RadioGroup
            style={{
              marginTop: "15px",
            }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleTypeChange}
            value={type}
          >
            <FormControlLabel
              style={{ marginRight: "4%" }}
              value="Yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              style={{}}
              value="No"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </Box>
      )}

      {type === "Yes" && (
        <Box>
          {!subjectId && (
            <Box>
              {faqData?.subQuestions?.map((subQuestion, index) => (
                <Box key={index}>
                  <TextFieldComponent
                    sx={{ "& fieldset": { border: "none" } }}
                    style={{
                      height: "48px",
                      width: "100%",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "9px",
                    }}
                    name={`Sub Question ${index + 1}`}
                    placeholder={`Enter Sub Question ${index + 1}`}
                    value={
                      faqData.subQuestions[index].trans.find(
                        (t) => t.local === "en"
                      ).question
                    }
                    onChange={(event) => {
                      const allowedCharacters =
                        /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
                      const inputValue = event.target.value;

                      // Check if the input matches the allowed pattern
                      if (allowedCharacters.test(inputValue)) {
                        handleSubQuestionChange(
                          index,
                          "en",
                          "question",
                          inputValue
                        );
                      }
                    }}
                  />

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#4C4C4C",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      Answer
                    </Typography>
                    <CustomTextArea
                      style={{
                        minHeight: "80px",
                        maxHeight: "200px",
                        maxWidth: "100%",
                        minWidth: "100%",
                        backgroundColor: "#FFF",
                        borderRadius: "9px",
                        boxShadow: "none",
                        border: "1px solid #E9C237",
                      }}
                      name="Answer"
                      placeholder="Enter Answer"
                      value={
                        faqData.subQuestions[index].trans.find(
                          (t) => t.local === "en"
                        ).answer
                      }
                      onChange={(event) => {
                        const allowedCharacters =
                          /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
                        const inputValue = event.target.value;

                        // Check if the input matches the allowed pattern
                        if (allowedCharacters.test(inputValue)) {
                          handleSubQuestionChange(
                            index,
                            "en",
                            "answer",
                            inputValue
                          );
                        }
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      alignItems: "center",
                      alignSelf: "flex-end",
                    }}
                  >
                    <Box
                      onClick={() => handleDeleteSubQuestion(index)}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          backgroundColor: "rgba(255, 69, 58, 0.3)",
                          borderRadius: "50%",
                          marginRight: "5px",
                          padding: "2px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FaMinus
                          color="#FF453A"
                          fontSize={12}
                          style={{
                            alignSelf: "center",
                          }}
                        />
                      </Box>
                      <Typography sx={{ color: "#FF453A", fontSize: "14px" }}>
                        Delete
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          {!subjectId && (
            <Box
              sx={{
                display: "flex",
                marginTop: "30px",
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                onClick={() => {
                  handleAddSubQuestion();
                }}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  backgroundColor: "rgba(233, 194, 55, 0.3)",
                  borderRadius: "50%",
                  marginRight: "5px",
                  padding: "2px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GoPlus
                  color="#E9C237"
                  fontSize={14}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                  }}
                />
              </Box>
              <Typography
                onClick={() => {
                  handleAddSubQuestion();
                }}
                sx={{
                  cursor: "pointer",
                  color: "#E9C237",
                  fontSize: "14px",
                }}
              >
                Add another sub question
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default StepOneTicketQuestions;

import Modal from "@mui/material/Modal";
import closeCircle from "../../svg/close-circle.svg";
import { useEffect, useState } from "react";
import { useEditPaymentInfo } from "../../services/apis/paymentInfo";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import ReusableSnackbar from "../../components/ReusableSnackbar";

export default function NewPaymentInfoDialog({
  open,
  setOpen,
  newData,
  fetchData,
}) {
  const { mutateAsync } = useEditPaymentInfo();
  const dispatch = useDispatch();
  const [data, setTheData] = useState({
    companyName: newData[0]?.value,
    companyAddress: newData[1]?.value,
    bankName: newData[2]?.value,
    accountNumber: newData[3]?.value,
    IBAN: newData[4]?.value,
    swiftCode: newData[5]?.value,
  });
  const validateFields = () => {
    return Object.values(data).every((field) => field && field.trim() !== "");
  };
  useEffect(() => {
    setTheData({
      companyName: newData[0]?.value,
      companyAddress: newData[1]?.value,
      bankName: newData[2]?.value,
      accountNumber: newData[3]?.value,
      IBAN: newData[4]?.value,
      swiftCode: newData[5]?.value,
    });
  }, [newData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;
    if (name === "companyName" || name === "bankName") {
      // Allow only English letters
      isValid = /^[A-Za-z\s]*$/.test(value);
    } else if (name === "accountNumber" ) {
      // Allow only numbers
      isValid = /^[0-9]*$/.test(value);
    } else if (name === "IBAN"|| name === "swiftCode") {
      // Allow only English letters and numbers for IBAN
      isValid = /^[A-Za-z0-9]*$/.test(value);
    }

    // Update state if value passes validation
    if (isValid) {
      setTheData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const onSubmit = async () => {
    if (!validateFields()) {
      dispatch(
        setData({
          severity: "error",
          openSnack: true,
          message: "Pleas Fill All The Fields",
        })
      );
      return;
    }
    await mutateAsync({ id: 3, meta: data }).then((response) => {
      if (response.status === 200) {
        fetchData();
        setOpen(false);
      }
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-[32px] p-6 w-[40rem]">
          <img
            src={closeCircle}
            alt="close circle"
            className="ml-auto cursor-pointer"
            onClick={() => setOpen(false)}
          />
          <div>
            <h1 className="text-[#595959] text-2xl mb-12">
              Edit Payment Information
            </h1>
            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col justify-center gap-2">
                <p className="text-sm text-[#595959]">Company Name</p>
                <input
                  type="text"
                  name="companyName"
                  value={data.companyName || ""}
                  onChange={handleChange}
                  className="border border-[#e6e6e6] py-3 pl-2 rounded-xl"
                />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-sm text-[#595959]">Company Address</p>
                <input
                  type="text"
                  name="companyAddress"
                  value={data.companyAddress || ""}
                  onChange={handleChange}
                  className="border border-[#e6e6e6] py-3 pl-2 rounded-xl"
                />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-sm text-[#595959]">Bank Name</p>
                <input
                  type="text"
                  name="bankName"
                  value={data.bankName || ""}
                  onChange={handleChange}
                  className="border border-[#e6e6e6] py-3 pl-2 rounded-xl"
                />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-sm text-[#595959]">Account Number</p>
                <input
                  type="text"
                  name="accountNumber"
                  value={data.accountNumber || ""}
                  onChange={handleChange}
                  className="border border-[#e6e6e6] py-3 pl-2 rounded-xl"
                />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-sm text-[#595959]">IBAN</p>
                <input
                  type="text"
                  name="IBAN"
                  value={data.IBAN || ""}
                  onChange={handleChange}
                  className="border border-[#e6e6e6] py-3 pl-2 rounded-xl"
                />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <p className="text-sm text-[#595959]">Swift Code</p>
                <input
                  type="text"
                  name="swiftCode"
                  value={data.swiftCode || ""}
                  onChange={handleChange}
                  className="border border-[#e6e6e6] py-3 pl-2 rounded-xl"
                />
              </div>
            </div>
            <div className="flex items-center justify-center gap-6 mt-12">
              <button
                className="text-white py-3 px-20 rounded-2xl border bg-[#e9c237]"
                onClick={onSubmit}
              >
                Save
              </button>
              <button
                className="text-[#e9c237] py-3 px-20 rounded-2xl border"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ReusableSnackbar />
    </div>
  );
}

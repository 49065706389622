import { IconButton, InputBase, Paper } from "@mui/material";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useDeletedUsers } from "../../services/apis/users";
import { useNavigate } from "react-router-dom";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import { useState } from "react";
import { handlePageChange } from "../../services/utils/filters";
import { DataGridStyle } from "../../styles";
import NewTable from "../../components/common/NewTable";

const DeletedUsers = () => {
  const navigate = useNavigate();
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    phone: null,
  });
  const { data, isFetching } = useDeletedUsers(queryParameters);
  const deletedUsersColumns = ItemsColumns([
    { field: "full_name", headerName: "Username" },
    { field: "deleted_at", headerName: "Delete Date", date: true },
    { field: "phone", headerName: "Phone number" },
    { field: "created", headerName: "Join Date", date: true },
    { field: "kyc_status", headerName: "KYC", kyc: true },
  ]);
  const handleSearchInput = (e) => {
    const input = e.target.value;
    let processedInput = input.replace(/^0+/, "");
    setQueryParameters((prev) => ({
      ...prev,
      phone: processedInput || null,
    }));
  };
  const handleRowClick = (row) => {
    navigate(`/users/${row.id}`);
  };
  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Deleted Users" marginL={"20px"} />
      <Box sx={{ display: "flex", width: "100%", gap: 2, p: 2 }}>
        <div className="flex items-center rounded-xl bg-white p-2 w-[95%] md:w-3/5">
          <IconButton type="button" aria-label="search">
            <img alt="search-normal" src={`../../assets/search-normal.png`} />
          </IconButton>
          <InputBase
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Email or Phone number....."
          />
        </div>
      </Box>
      <SecondBox>
        <Box height="100%" sx={{ ...DataGridStyle, overflowX: "auto" }}>
          <NewTable
            columns={deletedUsersColumns}
            isLoading={isFetching}
            data={data?.data?.result}
            totalItems={data?.data?.totalItems}
            totalPages={data?.data?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            handleRowClick={handleRowClick}
            currentPage={queryParameters}
          />
        </Box>
      </SecondBox>
    </Box>
  );
};

export default DeletedUsers;

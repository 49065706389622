import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { Box, Button } from "@mui/material";
import NewTable from "../../components/common/NewTable";
import { ItemsColumns } from "../../components/common/Columns";
import { useNavigate } from "react-router-dom";
import documentImg from "../../svg/document-download.svg";
import {
  useDeleteVoucher,
  useFetchVouchers,
} from "../../services/apis/voucher";
import { useState } from "react";
import { handlePageChange } from "../../services/utils/filters";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { IoFilterOutline } from "react-icons/io5";
import ActionAlert from "../user-profile/ActionAlert";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc); // Extend with utc plugin first
dayjs.extend(timezone); // Then extend with timezone plugin

const Voucher = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync } = useDeleteVoucher();
  const [id, setId] = useState("");
  const [openDeletedAlert, setOpenDeletedAlert] = useState(false);
  const handleCloseDeleteAlert = () => {
    setOpenDeletedAlert(false);
  };
  const handleDeleteVoucher = async () => {
    try {
      await mutateAsync(id);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
    handleCloseDeleteAlert();
  };
  const [filter, setFilter] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    is_deleted: false,
  });
  const { data: vouchers, isLoading } = useFetchVouchers(queryParameters);
  const voucherColumns = ItemsColumns([
    { field: "code", headerName: "Voucher Code" },
    {
      field: "start_date",
      headerName: "Valid From",
      specialRender: "customRender",
      renderFunction: (params) => {
        const formattedDate = dayjs
          .utc(params?.row?.start_date) // Interpret the date as UTC
          .tz(userTimeZone) // Convert to user's timezone
          .format("MMMM D, YYYY [at] HH:mm:ss");
        return <p>{formattedDate}</p>;
      },
    },
    {
      field: "end_date",
      headerName: "Expires On",
      specialRender: "customRender",
      renderFunction: (params) => {
        const formattedDate = dayjs
          .utc(params?.row?.end_date) // Interpret the date as UTC
          .tz(userTimeZone) // Convert to user's timezone
          .format("MMMM D, YYYY [at] HH:mm:ss");
        return <p>{formattedDate} </p>;
      },
    },
    { field: "totalUsed", headerName: "Usage" },
    {
      field: "Status",
      headerName: "Status",
      specialRender: "customRender",
      renderFunction: (params) => {
        const startDate = dayjs.tz(params?.row?.start_date, userTimeZone);
        const endDate = dayjs.tz(params?.row?.end_date, userTimeZone);
        const today = dayjs.tz(new Date(), userTimeZone);

        let status;
        if (startDate.isAfter(today)) {
          status = "Inactive";
        } else if (endDate.isBefore(today)) {
          status = "Expired";
        } else {
          status = "Active";
        }
        return <p>{status}</p>;
      },
    },
    {
      field: "limit_count",
      headerName: "Max Redemptions",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <p>
            {params?.row?.limit_count === 0
              ? "Unlimited"
              : params.row.limit_count}
          </p>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      actions: {
        edit: (params) => {
          navigate(`/voucher/${params?.row?.id}`, {
            state: {
              voucher: params?.row,
            },
          });
        },
        delete: (params) => {
          setId(params?.row?.id);
          setOpenDeletedAlert(true);
        },
      },
    },
  ]);
  const deletedVouchers = ItemsColumns([
    { field: "code", headerName: "Voucher Code" },
    {
      field: "start_date",
      headerName: "Valid From",
      date: true,
    },
    {
      field: "end_date",
      headerName: "Expires On",
      date: true,
    },
    { field: "totalUsed", headerName: "Usage" },
    {
      field: "Status",
      headerName: "Status",
      specialRender: "customRender",
      renderFunction: (params) => {
        const startDate = dayjs(params.row.start_date);
        const endDate = dayjs(params.row.end_date);
        const today = dayjs();

        let status;

        if (startDate.isAfter(today)) {
          status = "Inactive";
        } else if (endDate.isBefore(today)) {
          status = "Expired";
        } else {
          status = "Active";
        }

        return <p>{status}</p>;
      },
    },
    {
      field: "limit_count",
      headerName: "Max Redemptions",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <p>
            {params?.row?.limit_count === 0
              ? "Unlimited"
              : params.row.limit_count}
          </p>
        );
      },
    },
    {
      field: "deleted_at",
      headerName: "Deleted Date",
      date: true,
    },
  ]);
  return (
    <div className="">
      <DashboardMainHeader />
      <Header title={"Voucher Management"} marginL={4} />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
          mt: 1,
          p: 3,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div
          className={`
          flex items-center gap-8  bg-white  p-2 relative
          ${filter ? "  rounded-t-2xl " : "rounded-2xl"}
          `}
        >
          <p>
            {!queryParameters?.is_deleted
              ? "Current Vouchers"
              : "Deleted Vouchers"}{" "}
          </p>
          <IoFilterOutline
            onClick={() => {
              setFilter(!filter);
            }}
            size={20}
            style={{ cursor: "pointer", marginRight: "10px" }}
          />
          <div
            className={`
             -bottom-[200%] bg-white w-full left-0 
            ${filter ? "absolute z-20" : "hidden"}
            `}
          >
            <p
              onClick={() => {
                setFilter(false);
                setQueryParameters({ page: 0, is_deleted: false });
              }}
              className="p-2 hover:text-white hover:bg-[#e9c237] transition-all duration-100 ease-in-out cursor-pointer"
            >
              current Vouchers
            </p>
            <p
              onClick={() => {
                setFilter(false);
                setQueryParameters({ page: 0, is_deleted: true });
              }}
              className="p-2 rounded-b-2xl hover:text-white hover:bg-[#e9c237] transition-all duration-100 ease-in-out cursor-pointer"
            >
              deleted Vouchers
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center gap-5">
          <Button
            onClick={() => navigate("/new-voucher")}
            sx={{
              borderRadius: "9px",
              background: "#E9C237",
              "&:hover": {
                background: "#E9C237",
              },
              color: "#FFF",
              fontSize: "16px",
              textTransform: "none",
              width: "140px",
            }}
          >
            {"+ Add Voucher"}
          </Button>
          <Button
            // onClick={() => downloadUsers()}
            sx={{
              width: "140px",
              borderRadius: "9px",
              background: "#fff",
              border: "1px solid #E9C237",
              "&:hover": {
                background: "#fff",
              },
              color: "#E9C237",
              fontSize: "16px",
              textTransform: "none",
            }}
          >
            <img src={documentImg} alt="" className="mr-2" /> {"Export"}
          </Button>
        </div>
      </Box>
      <div className="p-4">
        <NewTable
          onCellClick={(params) => {
            if (params.field !== "action") {
              navigate(`/voucher/${params.row.id}`, {
                state: {
                  voucher: params?.row,
                },
              });
            }
          }}
          columns={
            queryParameters?.is_deleted ? deletedVouchers : voucherColumns
          }
          isLoading={isLoading}
          data={vouchers?.result}
          totalPages={vouchers?.totalPages}
          totalItems={vouchers?.totalItems}
          currentPage={queryParameters}
          handlePageChange={(newPage) =>
            handlePageChange(newPage, queryParameters, setQueryParameters)
          }
        />
      </div>
      <ReusableSnackbar />
      <ActionAlert
        openAlert={openDeletedAlert}
        handleCloseAlert={handleCloseDeleteAlert}
        handleReject={handleDeleteVoucher}
        message={`Are you sure you want to Delete this voucher ?`}
        label={"Delete"}
      />
    </div>
  );
};

export default Voucher;

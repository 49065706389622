import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";
import { getGoldTransactionType, transactionsAdjustments } from "../helpers";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
export const fetchFractions = async (q) => {
  const response = await axios.get(`/api/goldTransactions/getSendFractions`, {
    params: {
      page: q.page,
      orderBy: q.orderBy,
      sort: q.sort,
      date_from: q.date_from,
      date_to: q.date_to,
      phone: q.phone,
      goldBarIds: JSON.stringify(q.goldBarIds),
      goldType: JSON.stringify(q.goldType),
      goldTransactionType: JSON.stringify(q.goldTransactionType),
    },
  });
  return response.data;
};

export const useFetchFractions = (q) => {
  return useQuery(["fractions", q], () => fetchFractions(q), {
    // Add any options you want here
    refetchOnWindowFocus: false,
    enabled: !!q, // This ensures the query only runs if q is not null or undefined
  });
};

// Define the API function
const changeTransactionStatus = async (body) => {
  const response = await axios.post(`/api/transactions/changeStatus`, body);
  return response.data;
};

// Create a custom hook using React Query's useMutation
export const useChangeTransactionStatus = () => {
  const dispatch = useDispatch();

  return useMutation(changeTransactionStatus, {
    onError: (error) => {
      // Handle error here if needed
      if (error?.response?.data?.status === 422) {
        dispatch(
          setData({
            openSnack: true,
            message:
              error.response.data.validation?.reference_number ||
              error.response.data.validation?.iban,
          })
        );
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
      }
      console.error("Error changing transaction status:", error);
    },
  });
};
const fetchTransactions = async ({ queryKey }) => {
  const [, q] = queryKey;
  
  const response = await axios.get(`/api/transactions/index`, {
    params: {
      page: q.page,
      orderBy: q.orderBy,
      sort: q.sort,
      date_from: q.date_from,
      date_to: q.date_to,
      userId: q.id,
      phone: q.phone,
      nationalId: q.nationalId,
      type: JSON.stringify(q.type),
      status: JSON.stringify(q.status),
      email: q.email,
    },
  });
  const transactions = {
    data: transactionsAdjustments(response?.data?.data?.result),
    totalItems: response?.data?.data?.totalItems,
    totalPages: response?.data?.data?.totalPages,
    status: response?.status,
  };

  return transactions;
};

// Custom hook to use the transactions query
const useTransactions = (query) => {
  return useQuery(["transactions", query], fetchTransactions, {
    keepPreviousData: false, // Optional: This keeps the previous data while fetching new data
    enabled: !!query, // Ensure query is defined before running the query
    refetchOnWindowFocus: false,
  });
};

export default useTransactions;
export const fetchUserTransactions = async ({ queryKey }) => {
  const response = await axios.get(`/api/transactions/index`, {
    params: {
      userId: queryKey[1],
      page: queryKey[2]?.page,
      status: queryKey[2]?.type,
    },
  });
  const transactions = {
    data: transactionsAdjustments(response?.data?.data?.result),
    totalItems: response?.data?.data?.totalItems,
    totalPages: response?.data?.data?.totalPages,
    status: response?.status,
  };
  return transactions;
};

// Custom hook to use the transactions query
export const useUserTransactions = (id, params) => {
  return useQuery(["userTransactions", id, params], fetchUserTransactions, {
    keepPreviousData: true, // Optional: This keeps the previous data while fetching new data
    enabled: !!id, // Only run the query if id is truthy
    refetchOnWindowFocus: false,
  });
};
// Function to fetch gold transactions
const fetchGoldTransactions = async (q) => {
  try {
    const response = await axios.get(`/api/goldTransactions/index`, {
      params: {
        page: q.page,
        orderBy: q.orderBy,
        sort: q.sort,
        serial: q.serial,
        date_from: q.date_from,
        date_to: q.date_to,
        phone: q.phone,
        nationalId: q.nationalId,
        query: q.query,
        goldBarIds: JSON.stringify(q.goldBarIds),
        goldType: JSON.stringify(q.goldType),
        goldTransactionType: JSON.stringify(q.goldTransactionType),
      },
    });
    if (!response?.data?.data) {
      return {
        data: [],
        totalItems: 0,
        totalPages: 0,
      };
    }
    const transactionData = await transactionsAdjustments(
      response?.data?.data?.result || []
    );
    const goldTransactionData = await getGoldTransactionType(
      transactionData || []
    );

    const modifiedGT = goldTransactionData?.map((trans) => {
      const {
        user: { id: userId, full_name: userFullName, phone: userPhone } = {},
        ...rest
      } = trans;

      return {
        ...rest,
        user_id: userId,
        user_full_name: userFullName,
        user_phone: userPhone,
        totalWeight: trans?.totalWeight?.toFixed(3),
      };
    });

    const changedData = {
      data: modifiedGT,
      totalItems: response?.data?.data?.totalItems || 0,
      totalPages: response?.data?.data?.totalPages || 0,
      status: response?.status,
    };

    return changedData;
  } catch (error) {
    console.error("Error fetching gold transactions:", error);
    return {
      data: [],
      totalItems: 0,
      totalPages: 0,
    };
  }
};

// Custom hook to use the gold transactions query
const useGoldTransactions = (query) => {
  return useQuery(
    ["goldTransactions", query],
    () => fetchGoldTransactions(query),
    {
      keepPreviousData: true, // Optional: This keeps the previous data while fetching new data
      enabled: !!query, // Ensure query is defined before running the query
      refetchOnWindowFocus: false, // Disable automatic refetching on window/tab focus
    }
  );
};

export { useGoldTransactions, fetchGoldTransactions };

const fetchTransactionDetails = async (id) => {
  try {
    const response = await axios.get(`/api/transactions/details/${id}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const useTransactionDetails = (id) => {
  return useQuery(["TransactionDetails", id], () =>
    fetchTransactionDetails(id)
  );
};

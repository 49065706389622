import React, { useState } from "react";
import { Button, Box, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { axiosInstance as axios } from "../../../services/config";
import LoadingSpinner from "../../../components/LoadingSpinner";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { useRef } from "react";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import signup from "../../../svg/signup.svg";
import { allowedExtensions, maxFileSize } from "../../../data/globalData.js";
const RegistrationForm = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [secondImage, setSecondImage] = useState(null);
  const [file, setFile] = useState(null);
  const [secondfile, setSecondfile] = useState(null);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    full_name: "",
    nationalId: "",
  });
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const otp = queryParams.get("otp");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputKey, setInputKey] = useState(0);
  const [secondInputKey, setSecondInputKey] = useState(0);
  const fileInputRef = useRef();
  const secondFileInputRef = useRef();
  const handleFileInputChange = (event) => {
    if (event && event.target) {
      const selectedFile = event.target.files[0];

      if (selectedFile && selectedFile instanceof File) {
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          dispatch(
            setData({
              openSnack: true,
              message: "Invalid file type. Please upload an image.",
            })
          );
          return;
        }

        // Validate file size
        if (selectedFile.size > maxFileSize) {
          dispatch(
            setData({
              openSnack: true,
              message: "File size exceeds 20MB. Please upload a smaller file.",
            })
          );

          return;
        }
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (e) => {
          setImage(e.target.result);
        };
        reader.readAsDataURL(selectedFile);

        setFormData((prevPayload) => ({
          ...prevPayload,
          national_front: selectedFile,
        }));

        setInputKey(inputKey + 1);
      }
    }
  };
  const handleSecondFileInputChange = (event) => {
    if (event && event.target) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile instanceof File) {
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          dispatch(
            setData({
              openSnack: true,
              message: "Invalid file type. Please upload an image.",
            })
          );
          return;
        }

        // Validate file size
        if (selectedFile.size > maxFileSize) {
          dispatch(
            setData({
              openSnack: true,
              message: "File size exceeds 20MB. Please upload a smaller file.",
            })
          );

          return;
        }
        setSecondfile(selectedFile);
        const reader = new FileReader();
        reader.onload = (e) => {
          setSecondImage(e.target.result);
        };
        reader.readAsDataURL(selectedFile);
        setFormData((prevPayload) => ({
          ...prevPayload,
          national_back: selectedFile,
        }));
        setSecondInputKey(secondInputKey + 1);
      }
    }
  };
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleUploadSecondButtonClick = () => {
    secondFileInputRef.current.click();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setFile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDropSecondImage = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSecondfile(file);

    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSecondImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const register = async (form) => {
    try {
      const data = {
        email: email,
        password: form.password,
        full_name: form.full_name,
        otp: otp,
        nationalId: form.nationalId,
      };
      const fd = new FormData();
      fd.append("data", JSON.stringify(data));
      fd.append("national_front", file);
      fd.append("national_back", secondfile);
      const response = await axios.post(`/api/admin/register`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const isPasswordValid = (password) => {
    // Check if password has at least 8 characters
    if (password.length < 8) {
      setConfirmPasswordError("Password should be at least 8 characters");
      return false;
    }
    if (password.length > 30) {
      setConfirmPasswordError("Password should be 30 characters or less");
      return false;
    }
    // Check if password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      setConfirmPasswordError("Password should contain uppercase letter");
      return false;
    }

    // Check if password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      setConfirmPasswordError("Password should contain lowercase letter");
      return false;
    }
    // Check if password contains at least one special character
    if (!/[^A-Za-z0-9]/.test(password)) {
      setConfirmPasswordError("Password should contain special character");
      return false;
    }
    if (!/\d/.test(password)) {
      setConfirmPasswordError("Password should contain numbers");
      return false;
    }
    return true;
  };
  const isValidNationalId = (nationalId) => {
    if (isNaN(nationalId)) {
      dispatch(
        setData({
          openSnack: true,
          message: "National ID must be a number",
        })
      );
      return false;
    }
    if (nationalId.trim().length !== 14) {
      dispatch(
        setData({
          openSnack: true,
          message: "National ID must be 14 digits long",
        })
      );
      return false;
    }
    return true;
  };
  const isValidNationalName = (name) => {
    const nameParts = name.trim().split(" ");
    return nameParts.length === 4 && nameParts.every((part) => part.length > 0);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (
        !formData.full_name ||
        !formData.nationalId ||
        !formData.password ||
        !formData.confirmPassword ||
        !file ||
        !secondfile
      ) {
        setIsLoading(false);
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all required fields",
          })
        );
      } else if (!isValidNationalName(formData.full_name)) {
        setIsLoading(false);
        dispatch(
          setData({
            openSnack: true,
            message:
              "Please Enter your full name as displayed in the national id",
          })
        );
      } else if (!isValidNationalId(formData.nationalId)) {
        setIsLoading(false);
      } else if (formData.password !== formData.confirmPassword) {
        setConfirmPasswordError("Password doesn't match!");
        setIsLoading(false);
      } else if (!isPasswordValid(formData.password)) {
        setIsLoading(false);
      } else {
        const response = await register(formData);

        if (response.status === 200) {
          setIsLoading(false);
          localStorage.removeItem("token");
          navigate("/login");
        }
      }
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.validation.nationalId,
          })
        );
        setIsLoading(false);
      } else {
        dispatch(
          setData({
            openSnack: true,
            message: error.response.data.message,
          })
        );
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteImage = () => {
    setImage(null);
    setFile(null);
  };
  const handleDeleteSecondImage = () => {
    setSecondImage(null);
    setSecondfile(null);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <div className=" h-screen">
        <div className="h-full flex flex-col md:flex-row ">
          <div className="flex flex-col bg-white items-center justify-center w-full md:w-1/2 p-4">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl"
              autoComplete="off"
              noValidate
            >
              <Typography
                sx={{
                  color: "#4C4C4C",
                  fontSize: "40px",
                  height: "63px",
                }}
                variant="h3"
              >
                Signup
              </Typography>
              <TextFieldComponent
                type="text"
                style={{ width: "100%", height: "48px" }}
                name="Full Name"
                placeholder="Enter Full Name"
                value={formData.full_name}
                onChange={(event) => {
                  var temppayload = { ...formData };
                  temppayload.full_name = event.target.value;
                  setFormData({ ...temppayload });
                }}
              />
              <TextFieldComponent
                type="text"
                style={{ width: "100%", height: "48px" }}
                name="National ID"
                placeholder="Enter National ID"
                value={formData.nationalId}
                onChange={(event) => {
                  var temppayload = { ...formData };
                  temppayload.nationalId = event.target.value;
                  setFormData({ ...temppayload });
                }}
              />
              <div className="flex flex-col md:flex-row justify-between gap-4 mt-2">
                <TextFieldComponent
                  type="password"
                  showPassword={showPassword}
                  onTogglePasswordVisibility={() =>
                    setShowPassword(!showPassword)
                  }
                  name="Password"
                  placeholder="Enter Password"
                  value={formData.password}
                  error={confirmPasswordError}
                  onChange={(event) => {
                    var temppayload = { ...formData };
                    temppayload.password = event.target.value;
                    setFormData({ ...temppayload });
                  }}
                />
                <TextFieldComponent
                  type="password"
                  showPassword={showConfirmPassword}
                  onTogglePasswordVisibility={() =>
                    setConfirmShowPassword(!showConfirmPassword)
                  }
                  name="Confirm Password"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  error={confirmPasswordError}
                  onChange={(event) => {
                    var temppayload = { ...formData };
                    temppayload.confirmPassword = event.target.value;
                    setFormData({ ...temppayload });
                  }}
                />
              </div>
              <Box
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#4C4C4C",
                      marginBottom: "5px",
                    }}
                  >
                    National ID Front side
                  </Typography>
                  {!image && (
                    <div
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      className="mt-2 w-full h-[53px] border-2 border-dashed border-gray-300 flex items-center justify-center rounded-xl bg-gray-100 mb-2"
                    >
                      <img src={`../../assets/export.png`} alt="" />
                      <Box sx={{ display: "flex" }}>
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png,.gif,.bmp,.svg,.webp,.tiff,.ico,.heic,.heif,.avif"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                          />
                       
                        {!image && (
                          <Button
                            sx={{
                              color: "#E9C237",
                              textDecoration: "underLine",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                            onClick={handleUploadButtonClick}
                          >
                            click to upload
                          </Button>
                        )}
                        {image ? (
                          <img
                            src={image}
                            alt="Dropped"
                            height="152px"
                            width="100%"
                          />
                        ) : (
                          <p
                            style={{
                              color: "#808080",
                              fontSize: "12px",
                              marginTop: "9px",
                            }}
                          >
                            or drag and drop{"  "}
                          </p>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          color: "#CCCCCC",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontFamily: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        PNG or JPG (max. 3MB)
                      </Typography>
                    </div>
                  )}
                </Box>
                {file && (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "9px",
                      background: "#FFF",
                      boxShadow: "1px 1px 16px 0px rgba(0, 51, 102, 0.08)",
                      display: "flex",
                      height: "53px",
                      padding: "16px",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <img src={`../../assets/uploadicon.png`} alt="upload" />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          {file.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(0, 0, 0, 0.60)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            letterSpacing: "0.17px",
                            wordSpacing: "4px",
                          }}
                        >
                          {Math.ceil(file.size / 1024)}kb • Complete
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Avatar
                        sx={{
                          bgcolor: "#D9F4DD",
                          height: "24px",
                          width: "24px",
                          alignSelf: "center",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          src={`../../assets/tick-circle.png`}
                          alt="circle"
                        />
                      </Avatar>

                      <Avatar
                        onClick={handleDeleteImage}
                        sx={{
                          cursor: "pointer",
                          // height: "24px",
                          // width: "24px",
                          alignSelf: "center",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <img src={`../../assets/delete.png`} alt="delete" />
                      </Avatar>
                    </Box>
                  </Box>
                )}
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#4C4C4C",
                      marginBottom: "5px",
                    }}
                  >
                    National ID Back side
                  </Typography>
                  {!secondImage && (
                    <div
                      onDrop={handleDropSecondImage}
                      onDragOver={handleDragOver}
                      style={{
                        marginTop: "5px",
                        width: "100%",
                        height: "53px",
                        border: "2px dashed #ccc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        borderRadius: "12px",
                        background: "rgba(204, 204, 204, 0.3)",
                      }}
                    >
                      <img src={`../../assets/export.png`} alt="" />
                      <Box sx={{ display: "flex" }}>
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          ref={secondFileInputRef}
                          onChange={handleSecondFileInputChange}
                        />
                        {!secondImage && (
                          <Button
                            sx={{
                              color: "#E9C237",
                              textDecoration: "underLine",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                            onClick={handleUploadSecondButtonClick}
                          >
                            click to upload
                          </Button>
                        )}
                        {secondImage ? (
                          <img
                            src={secondImage}
                            alt="Dropped"
                            height="152px"
                            width="100%"
                          />
                        ) : (
                          <p
                            style={{
                              color: "#808080",
                              fontSize: "12px",
                              marginTop: "9px",
                            }}
                          >
                            or drag and drop{"  "}
                          </p>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          color: "#CCCCCC",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontFamily: "Roboto",
                          marginLeft: "5px",
                        }}
                      >
                        PNG or JPG (max. 3MB)
                      </Typography>
                    </div>
                  )}
                </Box>

                {secondfile && (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "9px",
                      background: "#FFF",
                      boxShadow: "1px 1px 16px 0px rgba(0, 51, 102, 0.08)",
                      display: "flex",
                      height: "53px",
                      padding: "16px",
                      justifyContent: "space-between",
                      marginTop: "5px",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <img src={`../../assets/uploadicon.png`} alt="upload" />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                          }}
                        >
                          {secondfile.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(0, 0, 0, 0.60)",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            letterSpacing: "0.17px",
                            wordSpacing: "4px",
                          }}
                        >
                          {Math.ceil(secondfile.size / 1024)}kb • Complete
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex" }}>
                      <Avatar
                        sx={{
                          bgcolor: "#D9F4DD",
                          height: "24px",
                          width: "24px",
                          alignSelf: "center",
                          marginRight: "15px",
                        }}
                      >
                        <img
                          src={`../../assets/tick-circle.png`}
                          alt="circle"
                        />
                      </Avatar>
                      <Avatar
                        onClick={handleDeleteSecondImage}
                        sx={{
                          cursor: "pointer",
                          // height: "24px",
                          // width: "24px",
                          alignSelf: "center",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <img src={`../../assets/delete.png`} alt="delete" />
                      </Avatar>
                    </Box>
                  </Box>
                )}
              </Box>
              <Button
                type="submit"
                variant="contained"
                className="bg-yellow-500 hover:bg-yellow-500 h-[48px] w-full mt-4"
                sx={{
                  backgroundColor: "#E9C237",
                  "&:hover": { backgroundColor: "#E9C237" },
                  height: "48px",
                  marginTop: "15px",
                }}
              >
                Signup
              </Button>
            </form>
          </div>
          <div className="flex h-full bg-[#F7F7F7] items-center justify-center w-full md:w-1/2 lg:w-1/2">
            <div className="w-[450px] h-[450px]">
              <img src={signup} alt="signup" className="h-full w-full" />
            </div>
          </div>
        </div>
        <ReusableSnackbar />
      </div>
    </>
  );
};

export default RegistrationForm;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  DialogContent,
  Dialog,
  OutlinedInput,
  Avatar,
} from "@mui/material";
import { addTown, editTown, useCities } from "../../services/apis/locations";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TextFieldComponent from "../../components/TextFieldComponent";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { MenuProps } from "../../components/common/MenuProps";
import useWindowSize from "../../hooks/useWindowSize";
const AddTownForm = React.forwardRef((props, ref) => {
  const { width } = useWindowSize();
  const { getAllTowns } = props;
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const [townPayload, setTownPayload] = useState({
    city_id: null,
    delivery: "",
    name_ar: "",
    name_en: "",
  });
  const { data: cities = [] } = useCities();
  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !townPayload.city_id ||
      !townPayload.delivery ||
      !townPayload.name_ar.trim() ||
      !townPayload.name_en.trim()
    ) {
      dispatch(
        setData({
          openSnack: true,
          message: "Please fill in all the fields.",
        })
      );
      return;
    }
    if (props.isEditMode) {
      try {
        setIsSubmitting(true);
        const fd = { ...townPayload };
        const city = cities?.data?.result.find(
          (city) => city.name_en === townPayload.city_id
        );
        if (city) fd.city_id = city.id;
        await editTown(props.data.id, fd);
        setSuccessDialogOpen(true);
        setTownPayload({
          city_id: null,
          delivery: "",
          name_ar: "",
          name_en: "",
        });
        await getAllTowns();
        props.handleClose();
      } catch (error) {
        if (error.response.status === 422) {
          const validationErrors = error.response.data.validation;
          const nameEnError = validationErrors.name_en;
          const nameArError = validationErrors.name_ar;
          let errorMessage = "";
          if (nameEnError) {
            errorMessage += `${nameEnError}`;
          } else if (nameArError) {
            errorMessage += `${nameArError}`;
          }
          dispatch(
            setData({
              openSnack: true,
              message: errorMessage,
            })
          );
        } else if (error.response.status === 400) {
          dispatch(
            setData({
              openSnack: true,
              message: "Area Already exists",
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        setIsSubmitting(true);
        const fd = { ...townPayload };
        const city = cities?.data?.result.find(
          (city) => city.name_en === townPayload.city_id
        );
        if (city) fd.city_id = city.id;
        await addTown(fd);
        props.handleClose();
        setSuccessDialogOpen(true);
        setTownPayload({
          city_id: null,
          delivery: "",
          name_ar: "",
          name_en: "",
        });
        await getAllTowns();
      } catch (error) {
        if (error?.response?.status === 422) {
          const validationErrors = error.response.data.validation;
          const nameEnError = validationErrors.name_en;
          const nameArError = validationErrors.name_ar;

          let errorMessage = "";
          if (nameEnError) {
            errorMessage += `${nameEnError}`;
          } else if (nameArError) {
            errorMessage += `${nameArError}`;
          }
          dispatch(
            setData({
              openSnack: true,
              message: errorMessage,
            })
          );
        } else if (error?.response?.status === 400) {
          dispatch(
            setData({
              openSnack: true,
              message: "Area Already exists",
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (props.isEditMode) {
      setTownPayload({
        city_id: props?.data?.city?.id,
        delivery: props.data.delivery,
        name_ar: props.data.name_ar,
        name_en: props.data.name_en,
      });
    } else {
      setTownPayload({
        city_id: null,
        delivery: "",
        name_ar: "",
        name_en: "",
      });
    }
  }, [props.data, props.isEditMode, props.open]);
  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            Area has been {props.isEditMode ? "Edited" : "added"} Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Areas
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="600px"
        open={props?.open}
        onClose={() => {
          setTownPayload({
            city_id: null,
            delivery: "",
            name_ar: "",
            name_en: "",
          });
          props.handleClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "24px" }}
      >
        <DialogContent
          sx={{
            height: "600px",
            width: width > 768 ? "721px" : "100%",

            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={props.handleClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <p className="text-[#4c4c4c] text-xl  md:self-start">
              {props.isEditMode ? "Edit Area" : "Add New Area"}
            </p>
            <Box>
              <div>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#4C4C4C",
                    marginBottom: "5px",
                    marginTop: "15px",
                  }}
                >
                  Select City
                </Typography>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    onChange={(event) => {
                      var temppayload = { ...townPayload };
                      temppayload.city_id = event.target.value;
                      setTownPayload({ ...temppayload });
                    }}
                    value={townPayload.city_id || ""}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Select City</em>;
                      }
                      const selectedCity = cities?.data?.result.find(
                        (city) => city?.id === selected
                      );
                      return selectedCity ? (
                        selectedCity.name_ar
                      ) : (
                        <em>Select City</em>
                      );
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {cities.data?.totalItems > 0 ? (
                      cities?.data?.result.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name_ar}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>
                        <em>No cities available</em>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: width > 768 ? "465px" : "fit-content",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Area name"
                placeholder="eg. 15 may"
                value={townPayload.name_en || ""}
                onChange={(event) => {
                  let allowedEnglishRegex = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
                  if (
                    event.target.value === "" ||
                    allowedEnglishRegex.test(event.target.value)
                  ) {
                    var temppayload = { ...townPayload };
                    temppayload.name_en = event.target.value;
                    setTownPayload({ ...temppayload });
                  }
                }}
              />
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: width > 768 ? "465px" : "fit-content",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="Area name (Arabic)"
                placeholder="eg. 15 مايو"
                value={townPayload.name_ar || ""}
                onChange={(event) => {
                  let allowedArabicRegex = /^[\u0600-\u06FF0-9"()-@'?:%.,\s]*$/;
                  if (allowedArabicRegex.test(event.target.value)) {
                    var temppayload = { ...townPayload };
                    temppayload.name_ar = event.target.value;
                    setTownPayload({ ...temppayload });
                  }
                }}
              />
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: width > 768 ? "465px" : "fit-content",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                type="number"
                name="Delivery Fees"
                placeholder="Enter Delivery Fees"
                value={townPayload.delivery || ""}
                onChange={(event) => {
                  var temppayload = { ...townPayload };
                  temppayload.delivery = event.target.value;
                  setTownPayload({ ...temppayload });
                }}
              />
            </Box>
            <div className="grid grid-cols-1 md:flex gap-4">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {!props.isEditMode ? "Add" : "Save"}
              </Button>

              <Button
                variant="outlined"
                autoCapitalize="false"
                sx={{
                  width: width > 768 ? "200px" : "150px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    borderColor: "#E9C237",
                  },
                }}
                onClick={() => {
                  props.handleClose();
                  setTownPayload({
                    city_id: null,
                    delivery: "",
                    name_ar: "",
                    name_en: "",
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
});

export default AddTownForm;

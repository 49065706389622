import { useNavigate, useParams } from "react-router-dom";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import TextView from "../../../components/common/TextView";
import { formDate } from "../../../services/helpers/index";
import { imageBaseUrl } from "../../../services/config";
import TextFieldComponent from "../../../components/TextFieldComponent";
import { Button } from "@mui/material";
import {
  useTransactionDetails,
  useChangeTransactionStatus,
} from "../../../services/apis/transactions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { useState } from "react";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import CustomDialog from "../../../components/CustomDialog";
import RejectionDialog from "./RejectionDialog";

const TransactionPending = () => {
  const navigate = useNavigate();
  const mutation = useChangeTransactionStatus();

  const { id } = useParams();
  const { data: transactionDetails, isFetching } = useTransactionDetails(id);
  const [formData, setFormData] = useState({
    accountNumber: "",
    iban: "",
    reference: "",
    amount: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const handleReferenceNumberChange = (e) => {
    setFormData({
      ...formData,
      reference: e.target.value.replace(/[\u0600-\u06FF\u0750-\u077F]/g, ""),
    });
  };
  const dispatch = useDispatch();

  const handleAccept = async () => {
    console.log("paymentMethod", transactionDetails);
    console.log("FormData", formData);
    try {
      setTransactionStatus("approved");
      if (
        transactionDetails?.type === 2 ||
        transactionDetails?.paymentMethod === "instaPay"
          ? !formData?.amount || !formData?.reference
          : !formData?.amount ||
            !formData?.reference ||
            !formData?.accountNumber
      ) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all required fields",
          })
        );
      } else if (isNaN(formData?.amount)) {
        dispatch(
          setData({
            openSnack: true,
            message: "Amount must be a number",
          })
        );
      } else {
        setIsSubmitting(true);
        let payload = {
          id: id,
          status: 2,
          amount: Number(formData?.amount),
          reference_number: formData?.reference,
        };
        if (formData?.iban) {
          payload = { ...payload, iban: formData?.iban };
        }
        if (formData?.accountNumber) {
          payload = {
            ...payload,
            account:
              formData?.accountNumber === "" ? null : formData?.accountNumber,
          };
        }

        await mutation.mutateAsync(payload);
        setSuccessDialogOpen(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReject = async () => {
    try {
      if (!rejectionReason) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please Select Rejection Reason",
          })
        );
      } else {
        setIsSubmitting(true);
        setTransactionStatus("rejected");
        const payload = {
          id: id,
          status: 3,
          rejection_reason: rejectionReason,
        };
        // console.log("payload rejected", payload);
        await mutation.mutateAsync(payload);
        setSuccessDialogOpen(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  if (isFetching) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <DashboardMainHeader />
      <div className="mt-12 mx-6">
        <div className="flex items-center gap-[0.625rem] mb-8">
          <img
            src={arrowLeft}
            className="cursor-pointer"
            alt="Arrow Left"
            onClick={() => {
              navigate("/transactions");
            }}
          />
          <p className="text-2xl text-[#333333] ">
            {transactionDetails?.type === 2
              ? "Review Withdraw"
              : "Review Deposit"}
          </p>
        </div>
        <div className="bg-white p-12 rounded-xl">
          <img
            src={`${imageBaseUrl}/${transactionDetails?.invoice}`}
            alt=""
            className="w-[14.5rem] mx-auto mb-8"
          />
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView
              title={"User Full Name (KYC)"}
              value={transactionDetails?.national_name}
            />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            <TextView
              title={"User Phone number"}
              value={transactionDetails?.phone}
            />
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]" />
          <div className="grid grid-cols-1 sm:grid-cols-3">
            <TextView title={"Transaction ID"} value={transactionDetails?.id} />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            <TextView
              title={"Payment Method"}
              value={transactionDetails?.paymentMethod}
            />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            <TextView title={"Transaction Status"} value={`Pending`} />
          </div>

          <hr className="text-[#e6e6e6] border-t-[1px]" />
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView
              title={"Transaction Amount"}
              value={`${Number(
                transactionDetails?.amount
              )?.toLocaleString()} EGP`}
            />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            <TextView
              title={"Created At"}
              value={formDate(transactionDetails?.created)}
            />
          </div>
          <hr className="text-[#e6e6e6] border-t-[1px]" />
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <TextView
              title={"Deposit date"}
              value={formDate(transactionDetails?.created)}
            />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
            <TextView
              title={"Updated At"}
              value={formDate(transactionDetails?.created)}
            />
            <hr className="text-[#e6e6e6] border-t-[1px] md:hidden " />
          </div>
          <div>
            {transactionDetails?.paymentMethod === "instaPay" ? null : (
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <TextFieldComponent
                  sx={{ "& fieldset": { border: "none" } }}
                  style={{
                    height: "48px",
                    width: "95%",
                    borderRadius: "9px",
                    backgroundColor: "#F5F5F5",
                  }}
                  type="text" // Use "text" instead of "number" for more control over allowed characters
                  name="Confirm Account Number"
                  placeholder="Enter Account Number"
                  value={formData?.accountNumber}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                    setFormData({ ...formData, accountNumber: numericValue });
                  }}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Mobile-friendly numeric keyboard
                />
                <TextFieldComponent
                  sx={{ "& fieldset": { border: "none" } }}
                  style={{
                    height: "48px",
                    width: "95%",
                    borderRadius: "9px",
                    backgroundColor: "#F5F5F5",
                  }}
                  name="Confirm IBAN (Optional)"
                  placeholder="Enter IBAN"
                  value={formData?.iban}
                  onChange={(e) => {
                    setFormData({ ...formData, iban: e.target.value });
                  }}
                />
              </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "95%",
                  borderRadius: "9px",
                  backgroundColor: "#F5F5F5",
                }}
                name={"Reference Number"}
                type="text" // Use "text" instead of "number" for more control over allowed characters                name="Reference Number"
                placeholder="Enter Reference Number"
                value={formData?.reference}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(
                    /[^a-zA-Z0-9-]/g,
                    ""
                  ); // Remove non-numeric characters
                  setFormData({ ...formData, reference: numericValue });
                }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Mobile-friendly numeric keyboard
              />

              <TextFieldComponent
                type={"number"}
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: "95%",
                  borderRadius: "9px",
                  backgroundColor: "#F5F5F5",
                }}
                name="Confirm Amount"
                placeholder="Enter Amount"
                value={formData?.amount}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

                  // Allow only if the value has 6 digits or fewer
                  if (numericValue.length <= 6) {
                    setFormData({ ...formData, amount: numericValue });
                  }
                }}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  maxLength: 6, // Adds a max length check for the input field
                }}
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:justify-end gap-4 mt-6">
            <Button
              disabled={isSubmitting}
              sx={{
                width: { xs: "100%", sm: "200px" },
                height: "43px",
                borderRadius: "12px",
                background: "#E9C237",
                color: "#FFF",
                fontSize: "18px",
                "&:hover": {
                  background: "#E9C237",
                },
                textTransform: "none",
              }}
              onClick={() => {
                handleAccept();
              }}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: { xs: "100%", sm: "200px" },
                height: "43px",
                borderRadius: "12px",
                "&:hover": {
                  borderColor: "#E9C237",
                  background: "#fff",
                },
                borderColor: "#E9C237",
                color: "#E9C237",
                fontSize: "18px",
                textTransform: "none",
              }}
              onClick={() => {
                setOpenAlert(true);
              }}
            >
              Reject
            </Button>
          </div>
        </div>

        <RejectionDialog
          setRejectionReason={setRejectionReason}
          rejectionReason={rejectionReason}
          openAlert={openAlert}
          handleCloseAlert={() => {
            setOpenAlert(false);
          }}
          handleReject={handleReject}
          message={`Are you sure you want to Reject this Transaction`}
          label={"Reject"}
        />
        <CustomDialog
          open={successDialogOpen}
          onClose={() => {
            setSuccessDialogOpen(false);
          }}
          title={
            transactionStatus === "approved"
              ? "Transaction has been approved successfully."
              : transactionStatus === "rejected"
              ? "Transaction has been rejected successfully."
              : "Transaction status"
          }
          message=""
          buttonText="Money Transactions"
          buttonLink="/transactions"
        />
        <ReusableSnackbar />
      </div>
    </div>
  );
};

export default TransactionPending;

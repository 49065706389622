import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import Header from "../../../components/Header";
import { DataGridStyle } from "../../../styles";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import {
  addRoleToPermission,
  changeKyc,
  fetchUserRoleDropdown,
  getforResource,
} from "../../../services/apis/roles-and-permissions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { DataGrid } from "@mui/x-data-grid";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import { MenuProps } from "../../../components/common/MenuProps";
import { SecondBox } from "../../../components/common/CustomBox";

const UserPermissions = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [resources, setResources] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleApiRequest = async () => {
    if (selectedRole) {
      try {
        const dataToSend = {
          user_group_id: selectedRole.id,
          type: 1,
        };
        const response = await getforResource(dataToSend);
        setResources(response.data.data);
      } catch (error) {
        console.log(error.response.data.message);
      }
    }
  };
  const handleCheckboxChangeKyc = async (resourceId) => {
    try {
      const updatedResources = resources.map((resourceKys) => {
        if (resourceKys.id === resourceId) {
          return { ...resourceKys, isKyc: !resourceKys.isKyc };
        }
        return resourceKys;
      });
      await changeKyc(resourceId);
      setResources(updatedResources);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error?.response?.data?.message,
        })
      );
    }
  };

  const handleCheckboxChange = async (resourceId, isChecked) => {
    try {
      const updatedResources = resources.map((resource) => {
        if (resource.id === resourceId) {
          return { ...resource, isChecked: !resource.isChecked };
        }
        return resource;
      });
      setResources(updatedResources);
      const dataToSend = {
        id: resourceId,
        role: selectedRole.id,
      };
      await addRoleToPermission(dataToSend);
      setOpen(true);
    } catch (error) {
      dispatch(
        setData({
          openSnack: true,
          message: error.response.data.message,
        })
      );
    }
  };
  const [role, setRole] = useState([]);
  const getRoleDropdown = async () => {
    try {
      const response = await fetchUserRoleDropdown();
      const role = response.data.data;
      setRole(role);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    if (selectedRole) {
      handleApiRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);
  useEffect(() => {
    getRoleDropdown();
  }, []);
  const columns = [
    {
      field: "name",
      headerName: "Actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "allow",
      headerName: "Allow",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "4%",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "#E9C237",
                  },
                  "&.MuiCheckbox-root:not(.Mui-checked)": {
                    color: "#4C4C4C",
                  },
                }}
                checked={!!params.row.isChecked}
                onChange={() => {
                  handleCheckboxChange(params.row.id);
                }}
              />
            }
          />
        </div>
      ),
    },
  ];
  if (selectedRole && selectedRole.name === "user") {
    columns.push({
      field: "KYCRedirection",
      headerName: "KYC Redirection",
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <FormControlLabel
          control={
            <Checkbox
              disabled={params.row.isChecked === 0}
              sx={{
                "&.Mui-checked": {
                  color: "#E9C237",
                },
                "&.MuiCheckbox-root:not(.Mui-checked)": {
                  color: "#4C4C4C",
                },
              }}
              checked={params.row.isKyc}
              onChange={() => handleCheckboxChangeKyc(params.row.id)}
            />
          }
        />
      ),
    });
  }
  const rows = resources.map((resource) => ({
    id: resource.id,
    name: resource.name,
    isChecked: resource.isChecked,
    isKyc: resource.isKyc,
  }));
  if (role.length === 0) return <LoadingSpinner />;
  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="User Permissions" marginL={"25px"} />
      <SecondBox>
        <Box
          m="40px 0 0 0"
          height="auto"
          style={{ paddingBottom: "2%" }}
          sx={DataGridStyle}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
              marginTop: "3%",
            }}
          >
            <Box sx={{ width: "90%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                  marginTop: "15px",
                }}
              >
                Select User Role
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={handleRoleChange}
                  value={selectedRole || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Select Role</em>;
                    }
                    return selected?.name;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {role.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {resources.length !== 0 && (
            <Box
              sx={{
                height: "auto",
                width: "100%",
                marginTop: "3%",
                justifyContent: "center",
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                disableSelectionOnClick
                autoHeight={true}
                disableColumnMenu={true}
                components={{
                  Toolbar: () => null,
                  Pagination: () => null,
                  Footer: () => null,
                }}
              />
            </Box>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Action added to Role
            </Alert>
          </Snackbar>
        </Box>
      </SecondBox>
      <ReusableSnackbar />
    </Box>
  );
};

export default UserPermissions;

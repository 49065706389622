import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  Avatar,
} from "@mui/material";
import { addCity, editCity } from "../../services/apis/locations";
import { useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import TextFieldComponent from "../../components/TextFieldComponent";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import useWindowSize from "../../hooks/useWindowSize";

const AddCityForm = React.forwardRef((props, ref,) => {
  const { width } = useWindowSize();
  const { getAllCities, refetch } = props;
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cityPayload, setCityPayload] = useState({
    name_en: "",
    name_ar: "",
  });
  const handleSuccessDialogClose = () => {
    refetch();
    setSuccessDialogOpen(false);
  };
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cityPayload.name_en.trim() || !cityPayload.name_ar.trim()) {
      dispatch(
        setData({
          openSnack: true,
          message: "Please fill in all the fields.",
        })
      );

      return;
    }
    if (props.isEditMode) {
      try {
        setIsSubmitting(true);
        await editCity(props.data.id, cityPayload);
        props.handleClose();
        setSuccessDialogOpen(true);
        setCityPayload({
          name_en: "",
          name_ar: "",
        });
        await getAllCities();
        props.handleClose();
      } catch (error) {
        if (error.response.status === 422) {
          const validationErrors = error.response.data.validation;
          const nameEnError = validationErrors.name_en;
          const nameArError = validationErrors.name_ar;

          let errorMessage = "";
          if (nameEnError) {
            errorMessage += `${nameEnError}`;
          } else if (nameArError) {
            errorMessage += `${nameArError}`;
          }
          dispatch(
            setData({
              openSnack: true,
              message: errorMessage,
            })
          );
        } else if (error.response.status === 400) {
          dispatch(
            setData({
              openSnack: true,
              message: "City Already exists",
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        setIsSubmitting(true);
        await addCity(cityPayload);
        props.handleClose();
        setSuccessDialogOpen(true);
        setCityPayload({
          name_en: "",
          name_ar: "",
        });
        await getAllCities();
      } catch (error) {
        if (error.response.status === 422) {
          const validationErrors = error.response.data.validation;
          const nameEnError = validationErrors.name_en;
          const nameArError = validationErrors.name_ar;

          let errorMessage = "";
          if (nameEnError) {
            errorMessage += `${nameEnError[0]?.message}`;
          } else if (nameArError) {
            errorMessage += `${nameArError[0]?.message}`;
          }
          dispatch(
            setData({
              openSnack: true,
              message: errorMessage,
            })
          );
        } else if (error.response.status === 400) {
          dispatch(
            setData({
              openSnack: true,
              message: "City Already exists",
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (props.isEditMode) {
      setCityPayload({
        name_en: props.data.name_en,
        name_ar: props.data.name_ar,
      });
    } else {
      setCityPayload({
        name_en: "",
        name_ar: "",
      });
    }
  }, [props.data, props.isEditMode, props.open]);

  return (
    <>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            City has been {props.isEditMode ? "Edited" : "added"} Successfully.
          </Typography>
          <Button
            onClick={() => {
              handleSuccessDialogClose();
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Go to Cities
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="721px"
        open={props?.open}
        onClose={() => {
          props.handleClose();
          setCityPayload({
            name_en: "",
            name_ar: "",
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "24px" }}
      >
        <DialogContent
          sx={{
            height: "450px",
            width: width > 768 ? "608px" : "100%",

            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={props.handleClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            {!props.isEditMode ? (
              <p className="text-xl">Add New City</p>
            ) : (
              <p className="text-xl">Edit City</p>
            )}

            <Box>
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: width > 768 ? "465px" : "100%",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="City name"
                placeholder="eg. Cairo"
                value={cityPayload.name_en}
                onChange={(event) => {
                  let allowedEnglishRegex = /^[a-zA-Z0-9"()@'?:%.,\- ]*$/;
                  if (allowedEnglishRegex.test(event.target.value)) {
                    var temppayload = { ...cityPayload };
                    temppayload.name_en = event.target.value;
                    setCityPayload({ ...temppayload });
                  }
                }}
              />
              <TextFieldComponent
                sx={{ "& fieldset": { border: "none" } }}
                style={{
                  height: "48px",
                  width: width > 768 ? "465px" : "100%",
                  backgroundColor: "#F7F7F7",
                  borderRadius: "9px",
                }}
                name="City name (Arabic)"
                placeholder="eg. القاهرة"
                value={cityPayload.name_ar}
                onChange={(event) => {
                  let allowedArabicRegex = /^[\u0600-\u06FF0-9"()-@'?:%.,\s]*$/;
                  if (allowedArabicRegex.test(event.target.value)) {
                    var temppayload = { ...cityPayload };
                    temppayload.name_ar = event.target.value;
                    setCityPayload({ ...temppayload });
                  }
                }}
              />
            </Box>
            <div className="grid grid-cols-1 md:flex gap-4">
              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#E9C237",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#E9C237",
                  },
                }}
                onClick={handleSubmit}
              >
                {!props.isEditMode ? "Add" : "Save"}
              </Button>

              <Button
                variant="outlined"
                autoCapitalize="false"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#E9C237",
                  fontWeight: 600,
                  borderColor: "#E9C237",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    borderColor: "#E9C237",
                  },
                }}
                onClick={() => {
                  props.handleClose();
                  setCityPayload({
                    name_en: "",
                    name_ar: "",
                  });
                }}
              >
                Cancel
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
      <ReusableSnackbar />
    </>
  );
});

export default AddCityForm;

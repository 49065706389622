import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  // Grid,
  Input,
} from "@mui/material";
import * as XLSX from "xlsx"; // Import the xlsx library
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Header from "../../components/Header";
import documentImg from "../../svg/document-download.svg";
import { useAddVoucher } from "../../services/apis/voucher";
import { IoMdDownload } from "react-icons/io";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/CustomDialog";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc); // Extend with utc plugin first
dayjs.extend(timezone); // Then extend with timezone plugin
// Validation schema using yup
const validationSchema = yup.object().shape({
  voucherCode: yup
    .string()
    .required("Voucher code is required")
    .max(8, "Voucher code must be at most 8 characters")
    .matches(/^\S*$/, "Voucher code must not contain spaces"),
  discountType: yup.string().required(),
  percentageDiscount: yup.number().when("discountType", {
    is: "Percentage Discount",
    then: yup
      .number()
      .required("Percentage discount is required")
      .positive()
      .max(100, "percentage discount cannot exceed 100%"),
  }),
  maxDiscountCap: yup.number().when("discountType", {
    is: "Percentage Discount",
    then: yup.number().required("Max discount cap is required").positive(),
  }),
  discountAmount: yup.number().when("discountType", {
    is: "Fixed Amount Discount",
    then: yup
      .number()
      .required("Discount amount is required")
      .positive("Amount Discount must be a positive number"),
  }),
  minOrderValue: yup
    .number()
    .required("Min Order Value is required")
    .positive("Min Order Value must be a positive number"),
  validFrom: yup
    .date()
    .required("Valid From date is required")
    .typeError("Invalid date"),
  validTo: yup
    .date()
    .required("Valid To date is required")
    .typeError("Invalid date")
    .min(yup.ref("validFrom"), "Valid To date cannot be before Valid From date")
    .test(
      "dates-not-same",
      "Valid To date cannot be the same as Valid From date",
      function (value) {
        const { validFrom } = this.parent;
        return value && validFrom && value.getTime() !== validFrom.getTime();
      }
    ),
  usageLimit: yup.number().when("usageLimitType", {
    is: "Limited", // Only required if usageLimitType is "Limited"
    then: yup.number().required("Usage limit is required").positive(),
    otherwise: yup.number().nullable(), // Allow null when unlimited
  }),

  // Use limit per user validation
  useLimitPerUser: yup.number().when("useLimitPerUserType", {
    is: "More than one", // Only required if useLimitPerUserType is "More than one"
    then: yup.number().required("Use limit per user is required").positive(),
    otherwise: yup.number().nullable(), // Allow null when on-time
  }),
  userFile: yup.mixed().when("users", {
    is: "Specific Users",
    then: yup
      .mixed()
      .required("File is required")
      .test("fileSize", "File is too large", (value) => {
        return value && value.size <= 10485760; // 10MB size limit
      })
      .test("fileType", "Only Excel files are allowed", (value) => {
        return (
          value &&
          [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ].includes(value.type)
        );
      })
      .test("fileExtension", "File must have .xlsx extension", (value) => {
        return value && value.name.endsWith(".xlsx");
      }),
    otherwise: yup.mixed().nullable(), // If users is not "Specific Users", allow null or no file
  }),
});
const downloadTemplate = () => {
  // Define your template data
  const templateData = [
    { id: 1, employee: "John Doe" },
    { id: 2, employee: "Jane Smith" },
    // You can add more rows to the template
  ];

  // Create a worksheet from the data
  const worksheet = XLSX.utils.json_to_sheet(templateData);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

  // Download the workbook as an Excel file
  XLSX.writeFile(workbook, "template.xlsx");
};
const NewVoucher = () => {
  const navigate = useNavigate();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  // Using react-hook-form
  const {
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      voucherCode: "",
      discountType: "Percentage Discount",
      discountAmount: 0,
      percentageDiscount: 0,
      maxDiscountCap: 0,
      minOrderValue: "",
      validFrom: null,
      validTo: null,
      usageLimitType: "Limited",
      usageLimit: 0,
      useLimitPerUserType: "More than one",
      useLimitPerUser: 0,
      users: "Specific Users",
      userFile: null,
      theFile: null,
    },
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
  });
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const timezoneOffset = new Date().getTimezoneOffset() / 60; // Offset in minutes from UTC
  const { mutateAsync, isLoading } = useAddVoucher();
  const [voucher, setVoucher] = useState({
    voucherCode: "",
    discountType: "Percentage Discount",
    discountAmount: 0,
    percentageDiscount: "",
    maxDiscountCap: 0,
    minOrderValue: "",
    validFrom: null,
    validTo: null,
    usageLimitType: "Limited",
    usageLimit: 0,
    useLimitPerUserType: "More than one",
    useLimitPerUser: 0,
    users: "Specific Users",
    userFile: null,
    theFile: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Remove spaces from the voucherCode input
    if (name === "voucherCode") {
      const sanitizedValue = value.replace(/\s+/g, ""); // Remove all spaces
      setValue(name, sanitizedValue);
      setVoucher((prev) => ({
        ...prev,
        [name]: sanitizedValue,
      }));
    } else {
      setValue(name, value);
      setVoucher((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    clearErrors(name);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming the first sheet contains the data
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert sheet to JSON format
      const jsonSheet = XLSX.utils.sheet_to_json(worksheet);

      // Extract IDs from the "id" column
      const extractedIds = jsonSheet.map((row) => row.id);
      setVoucher((prev) => ({
        ...prev,
        userFile: extractedIds,
      }));
      setValue("userFile", file); // Update the form value

      setVoucher((prev) => ({
        ...prev,
        theFile: file,
      }));
    };
    // Clear the error for userFile once the file is valid
    clearErrors("userFile");
    reader.readAsArrayBuffer(file);
  };
  const onSubmit = async (formData) => {
    const formateStartDate = dayjs(formData.validFrom)
      .subtract(
        timezoneOffset >= 0 ? timezoneOffset : Math.abs(timezoneOffset),
        "hours"
      )
      .format("YYYY-MM-DDTHH:mm:ss");

    const formateEndDate = dayjs(formData.validTo)
      .subtract(
        timezoneOffset >= 0 ? timezoneOffset : Math.abs(timezoneOffset),
        "hours"
      )
      .format("YYYY-MM-DDTHH:mm:ss");

    let sentData = {
      code: formData.voucherCode,
      discount_type:
        formData.discountType === "Percentage Discount"
          ? "percentage"
          : "fixed",
      min_order: formData.minOrderValue,
      start_date: formateStartDate,
      end_date: formateEndDate,
      limit_count:
        formData.usageLimitType === "Limited" ? formData.usageLimit : 0,
      user_limit_count:
        formData.useLimitPerUserType === "More than one"
          ? formData.useLimitPerUser
          : 1,
      user_ids:
        formData.users === "All Users"
          ? null
          : formData.users === "New Users"
          ? []
          : voucher.userFile,
      for_first_order: formData?.users === "New Users" ? 1 : 0,
    };

    if (formData.discountType !== "Percentage Discount") {
      sentData.value = formData.discountAmount;
    } else {
      sentData.percentage = formData.percentageDiscount;
      sentData.percentage_limit = formData.maxDiscountCap;
    }
    await mutateAsync(sentData)
      .then(() => {
        setSuccessDialogOpen(true);
      })
      .catch((error) => {
        let errorMessage;
        if (
          error?.response?.data?.validation?.start_date ===
          '"start_date" must be greater than or equal to "now"'
        ) {
          errorMessage = "Start date must be Greater than current date";
        } else if (
          error?.response?.data?.validation?.code[0]?.message ===
            "[ 'code', 'deleted_at' ] already in use." ||
          error?.response?.data?.validation?.deleted_at[0]?.message ===
            "[ 'code', 'deleted_at' ] already in use."
        ) {
          errorMessage = "Voucher code already exists";
        }
        dispatch(
          setData({
            severity: "error",
            openSnack: true,
            message: errorMessage || "Something went wrong",
          })
        );
      });
  };

  const handleCancel = () => {
    navigate("/voucher");
  };

  return (
    <div className="m-10">
      <Header title={"Add New Voucher"} marginB={10} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white p-10 rounded-2xl flex flex-col gap-6 border ">
          <div className="grid grid-cols-2 gap-8 items-center">
            <div>
              <h2 className="text-[#595959] text-sm font-ibm mb-1">
                Voucher Code
              </h2>
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                  width: "100%",
                  background: "#f5f5f5",
                  borderRadius: "12px",
                }}
                placeholder="eg. FIRST10"
                fullWidth
                name="voucherCode"
                value={voucher.voucherCode}
                onChange={handleInputChange}
              />
              {errors.voucherCode && (
                <p className="text-red-600 text-xs mt-1 ">
                  {errors.voucherCode.message}
                </p>
              )}
            </div>

            <FormControl component="fieldset">
              <FormLabel component="legend">Discount Type</FormLabel>
              <RadioGroup
                row
                name="discountType"
                value={voucher.discountType}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="Percentage Discount"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237", // Set the checked color to yellow
                        },
                      }}
                    />
                  }
                  label="Percentage Discount"
                />
                <FormControlLabel
                  value="Fixed Amount Discount"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237", // Set the checked color to yellow
                        },
                      }}
                    />
                  }
                  label="Fixed Amount Discount"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div
            className={`grid gap-8 items-center ${
              voucher.discountType === "Percentage Discount"
                ? " grid-cols-3"
                : " grid-cols-2"
            }`}
          >
            {voucher.discountType === "Percentage Discount" ? (
              <>
                <div>
                  <h2 className="text-[#595959] text-sm font-ibm mb-1">
                    Percentage Discount
                  </h2>
                  <TextField
                    sx={{
                      "& fieldset": { border: "none" },
                      width: "100%",
                      background: "#f5f5f5",
                      borderRadius: "12px",
                    }}
                    placeholder="eg. 20%"
                    fullWidth
                    type="number"
                    name="percentageDiscount"
                    value={voucher.percentageDiscount}
                    onChange={handleInputChange}
                  />
                  {errors.percentageDiscount && (
                    <p className="text-red-600 text-xs mt-1 ">
                      {errors?.percentageDiscount?.message ===
                      'percentageDiscount must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
                        ? "percentageDiscount is required"
                        : errors?.percentageDiscount?.message}
                    </p>
                  )}
                </div>
                <div>
                  <h2 className="text-[#595959] text-sm font-ibm mb-1">
                    Max Discount Cap
                  </h2>
                  <TextField
                    sx={{
                      "& fieldset": { border: "none" },
                      width: "100%",
                      background: "#f5f5f5",
                      borderRadius: "12px",
                    }}
                    placeholder="eg. 20%"
                    fullWidth
                    type="number"
                    name="maxDiscountCap"
                    value={voucher.maxDiscountCap}
                    onChange={handleInputChange}
                  />
                  {errors.maxDiscountCap && (
                    <p className="text-red-600 text-xs mt-1 ">
                      {errors?.maxDiscountCap?.message ===
                      'maxDiscountCap must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
                        ? "maxDiscountCap is required"
                        : errors?.maxDiscountCap?.message}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <>
                <div>
                  <h2 className="text-[#595959] text-sm font-ibm mb-1">
                    Amount Discount
                  </h2>
                  <TextField
                    sx={{
                      "& fieldset": { border: "none" },
                      width: "100%",
                      background: "#f5f5f5",
                      borderRadius: "12px",
                    }}
                    placeholder="eg. 20%"
                    fullWidth
                    type="number"
                    name="discountAmount"
                    value={voucher.discountAmount}
                    onChange={handleInputChange}
                  />
                  {errors.discountAmount && (
                    <p className="text-red-600 text-xs mt-1 ">
                      {errors?.discountAmount?.message ===
                      'Amount Discount must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
                        ? "Amount Discount is required"
                        : errors?.discountAmount?.message}
                    </p>
                  )}
                </div>
              </>
            )}
            <div>
              <h2 className="text-[#595959] text-sm font-ibm mb-1">
                Minimum Order Value
              </h2>
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                  width: "100%",
                  background: "#f5f5f5",
                  borderRadius: "12px",
                }}
                placeholder="eg. 20%"
                fullWidth
                type="number"
                name="minOrderValue"
                value={voucher.minOrderValue}
                onChange={handleInputChange}
              />
              {errors.minOrderValue && (
                <p className="text-red-600 text-xs mt-1 ">
                  {errors?.minOrderValue?.message ===
                  'minOrderValue must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
                    ? "Min Order Value is required"
                    : errors?.minOrderValue?.message}
                </p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8">
            <div className="w-full">
              <h2 className="text-[#595959] text-sm font-ibm mb-1">
                Valid From
              </h2>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  disablePast
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    background: "#f5f5f5",
                    borderRadius: "12px",
                  }}
                  placeholder="Select Start Date & Time"
                  value={voucher.validFrom}
                  onChange={(newValue) => {
                    setVoucher((prev) => ({ ...prev, validFrom: newValue }));
                    setValue("validFrom", newValue); // Update the form value
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                {errors.validFrom && (
                  <p className="text-red-600 text-xs mt-1 ">
                    {errors.validFrom.message}
                  </p>
                )}
              </LocalizationProvider>
            </div>
            <div className="w-full">
              <h2 className="text-[#595959] text-sm font-ibm mb-1">Valid To</h2>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  disablePast
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    background: "#f5f5f5",
                    borderRadius: "12px",
                  }}
                  placeholder="Select End Date & Time"
                  value={voucher.validTo}
                  onChange={(newValue) => {
                    setVoucher((prev) => ({ ...prev, validTo: newValue }));
                    setValue("validTo", newValue); // Update the form value
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                {errors.validTo && (
                  <p className="text-red-600 text-xs mt-1 ">
                    {errors.validTo.message}
                  </p>
                )}
              </LocalizationProvider>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8">
            <FormControl component="fieldset">
              <FormLabel component="legend">Usage Limit</FormLabel>
              <RadioGroup
                row
                name="usageLimitType"
                value={voucher.usageLimitType}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="Unlimited"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237", // Set the checked color to yellow
                        },
                      }}
                    />
                  }
                  label="Unlimited"
                />
                <FormControlLabel
                  value="Limited"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237", // Set the checked color to yellow
                        },
                      }}
                    />
                  }
                  label="Limited"
                />
              </RadioGroup>
            </FormControl>

            {voucher.usageLimitType === "Limited" && (
              <div>
                <h2 className="text-[#595959] text-sm font-ibm mb-1">
                  Usage Limit
                </h2>
                <TextField
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    background: "#f5f5f5",
                    borderRadius: "12px",
                  }}
                  placeholder="eg. 100"
                  fullWidth
                  type="number"
                  name="usageLimit"
                  value={voucher.usageLimit}
                  onChange={handleInputChange}
                />
                {errors.usageLimit && (
                  <p className="text-red-600 text-xs mt-1 ">
                    {errors?.usageLimit?.message ===
                    'usageLimit must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
                      ? "usageLimit is required"
                      : errors?.usageLimit?.message}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 gap-8">
            <FormControl component="fieldset">
              <FormLabel component="legend">Use Limit per User</FormLabel>
              <RadioGroup
                row
                name="useLimitPerUserType"
                value={voucher.useLimitPerUserType}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="On-time"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237", // Set the checked color to yellow
                        },
                      }}
                    />
                  }
                  label="On-time"
                />
                <FormControlLabel
                  value="More than one"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#E9C237", // Set the checked color to yellow
                        },
                      }}
                    />
                  }
                  label="More than one"
                />
              </RadioGroup>
            </FormControl>

            {voucher.useLimitPerUserType === "More than one" && (
              <div>
                <h2 className="text-[#595959] text-sm font-ibm mb-1">
                  Use limit per User
                </h2>
                <TextField
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    background: "#f5f5f5",
                    borderRadius: "12px",
                  }}
                  placeholder="eg. 100"
                  fullWidth
                  type="number"
                  name="useLimitPerUser"
                  value={voucher.useLimitPerUser}
                  onChange={handleInputChange}
                />
                {errors.useLimitPerUser && (
                  <p className="text-red-600 text-xs mt-1 ">
                    {errors?.useLimitPerUser?.message ===
                    'useLimitPerUser must be a `number` type, but the final value was: `NaN` (cast from the value `""`).'
                      ? "useLimitPerUser is required"
                      : errors?.useLimitPerUser?.message}
                  </p>
                )}
              </div>
            )}
          </div>
          <FormControl component="fieldset">
            <FormLabel component="legend">Users</FormLabel>
            <RadioGroup
              row
              name="users"
              value={voucher.users}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="All Users"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#E9C237", // Set the checked color to yellow
                      },
                    }}
                  />
                }
                label="All Users"
              />
              <FormControlLabel
                value="New Users"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#E9C237", // Set the checked color to yellow
                      },
                    }}
                  />
                }
                label="New Users (No Purchase Yet)"
              />
              <FormControlLabel
                value="Specific Users"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#E9C237", // Set the checked color to yellow
                      },
                    }}
                  />
                }
                label="Specific Users"
              />
            </RadioGroup>
          </FormControl>

          {voucher.users === "Specific Users" && (
            <>
              <div className="flex items-center gap-4">
                <p className="text-[#595959] ">Select user file from here</p>
                <Button
                  variant="outlined"
                  sx={{
                    width: "100px",
                    borderRadius: "9px",
                    border: "1px solid #E9C237",
                    color: "#E9C237",
                    fontSize: "12px",
                    textTransform: "none",
                    position: "relative",
                  }}
                  onClick={() => {
                    if (imageRef.current) {
                      imageRef.current.click();
                    }
                  }}
                >
                  <img src={documentImg} alt="" className="mr-2" /> {"Export"}
                  <Input
                    type="file"
                    name="userFile"
                    onChange={handleFileUpload}
                    inputRef={imageRef}
                    sx={{ display: "none" }}
                    accept=".csv,.xlsx" // Specify acceptable file types if needed
                  />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    width: "100px",
                    borderRadius: "9px",
                    border: "1px solid ",
                    fontSize: "12px",
                    textTransform: "none",
                    position: "relative",
                  }}
                  onClick={downloadTemplate} // Trigger the template download
                >
                  <IoMdDownload size={18} />
                  <p className="ml-2"> {"Template"}</p>{" "}
                </Button>
                {voucher.userFile && (
                  <span className="text-sm text-gray-500">
                    {voucher.theFile.name}
                  </span>
                )}
                {errors.userFile && (
                  <p className="text-red-600 text-xs mt-1 ">
                    {errors?.userFile?.message}
                  </p>
                )}
              </div>
            </>
          )}

          <div className="flex items-center justify-end gap-8">
            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                backgroundColor: "#E9C237",
                textTransform: "none",
                width: 150,
                fontSize: "16px",
              }}
            >
              Save
            </Button>

            <Button
              disabled={isLoading}
              variant="outlined"
              color="primary"
              onClick={handleCancel}
              sx={{
                borderColor: "#E9C237",
                textTransform: "none",
                color: "#E9C237",
                width: 150,
                fontSize: "16px",
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
      <ReusableSnackbar />
      <CustomDialog
        open={successDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false);
          navigate("/voucher");
        }}
        title="Voucher has been Added Successfully."
        message=""
        buttonText="Go to Vouchers"
      />
    </div>
  );
};

export default NewVoucher;

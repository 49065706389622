import dayjs from "dayjs";

export const handleFromDate = (date, queryParameters, setQueryParameters) => {
  const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
  setQueryParameters({ ...queryParameters, date_from: formattedDate });
};

export const handleToDate = (date, queryParameters, setQueryParameters) => {
  const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : "";
  setQueryParameters({ ...queryParameters, date_to: formattedDate });
};

export const handlePageChange = async (
  page,
  queryParameters,
  setQueryParameters
) => {
  setQueryParameters({ ...queryParameters, page });
};

export const handleSort = async (
  params,
  queryParameters,
  setQueryParameters
) => {
  if (params.length !== 0) {
    const orderBy =
      params[0].field === "orderStatus" ? "order_status_id" : params[0].field;
    const sort = params[0].sort === "asc" ? 1 : -1;
    setQueryParameters({ ...queryParameters, orderBy, sort });
  }
};

export const handleFilterByNumber = (
  value,
  type,
  queryParameters,
  setQueryParameters
) => {
  const valInNum = parseInt(value);
  if (queryParameters[type]?.includes(valInNum)) {
    let updatedFilter = queryParameters[type].filter((p) => p !== valInNum);
    if (updatedFilter.length === 0) updatedFilter = "";
    setQueryParameters({
      ...queryParameters,
      [type]: updatedFilter,
    });
  } else {
    setQueryParameters({
      ...queryParameters,
      [type]: [...queryParameters[type], valInNum],
    });
  }
};

export const handleFilterByString = (
  value,
  type,
  queryParameters,
  setQueryParameters
) => {
  if (queryParameters[type]?.includes(value)) {
    let updatedFilter = queryParameters[type].filter(
      (status) => status !== value
    );
    if (updatedFilter.length === 0) updatedFilter = "";
    setQueryParameters({
      ...queryParameters,
      [type]: updatedFilter,
    });
  } else {
    setQueryParameters({
      ...queryParameters,
      [type]: [...queryParameters[type], value],
    });
  }
};

export function checkString(input, queryParameters, setQueryParameters) {
  console.log("queryParameters", queryParameters);
  // Regular expressions for 12 digits, 16 digits, email, and normal string
  setQueryParameters({
    ...queryParameters,
    phone: null,
    nationalId: null,
    email: null,
    serial: null,
    id: null,
  });
  const regex10Digits = /^\d{10}$/;
  const regex14Digits = /^\d{14}$/;
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const regexSerial = /^\d{12}$/;
  if (regex10Digits.test(input)) {
    setQueryParameters({ ...queryParameters, phone: input });
    return "10 digits";
  } else if (regex14Digits.test(input)) {
    setQueryParameters({ ...queryParameters, nationalId: input });

    return "14 digits";
  } else if (regexEmail.test(input)) {
    setQueryParameters({ ...queryParameters, email: input });
    return "Email";
  } else if (regexSerial.test(input)) {
    setQueryParameters({ ...queryParameters, serial: input });
    return "12 digits";
  }
  // else if (regexNumeric.test(input)) {
  //   console.log("input", input.length === 10);
  //   console.log("input", input);
  //   // setQueryParameters({ ...queryParameters, id: input });
  //   return "Numeric";
  // }
}

export const handleIsOnlineStatus = (
  value,
  queryParameters,
  setQueryParameters
) => {
  setQueryParameters({ ...queryParameters, is_online: value });
};
export const handleIsRoleType = (
  value,
  queryParameters,
  setQueryParameters
) => {
  setQueryParameters({ ...queryParameters, role_type: value });
};

import { useDispatch } from "react-redux";
import TicketMessage from "./TicketMessage";
import { InputAdornment, Skeleton, TextField } from "@mui/material";
import { removeItem } from "../../../store/slices/ticketSlice";

const SecondTicketPanel = ({
  containerRef,
  data,
  formData,
  handleKeyPress,
  setFormData,
  handleSave,
  isLoading,
}) => {
  const dispatch = useDispatch();
  dispatch(removeItem(data?.id));
  return (
    <div className="flex flex-col items-center      ">
      <div
        ref={containerRef}
        className="pt-2 flex-grow overflow-y-auto  h-[50vh]  flex-col flex scrollbar-hide relative w-full"
      >
        <div className="bg-white">
          <div className=" overflow-y-auto scrollbar-hide  px-6  ">
            {isLoading ? (
              <div className="flex items-center flex-col gap-4">
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
                <Skeleton sx={{ width: 1250 }} />
              </div>
            ) : (
              data?.replies?.map((message, index) => (
                <TicketMessage
                  key={`${message.created} + ${message?.message} + ${index}`}
                  message={message.message}
                  admin_id={message.admin_id}
                  created={message.created}
                />
              ))
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <Skeleton sx={{ width: 200 }} />
      ) : (
        data.status !== 4 && (
          <div className="flex items-center justify-center mt-auto bg-white rounded-b-3xl   static bottom-0 w-full">
            <TextField
              sx={{
                "& fieldset": { border: "none" },
                height: "48px",
                borderRadius: "12px",
                backgroundColor: "#F5F5F5",
              }}
              placeholder="Type your replay here ....."
              fullWidth
              value={formData.reply}
              onKeyDown={handleKeyPress}
              onChange={(event) => {
                var temppayload = { ...formData };
                temppayload.reply = event.target.value;
                setFormData({ ...temppayload });
              }}
              InputProps={{
                endAdornment: formData.reply.trim() !== "" && (
                  <InputAdornment position="end">
                    <svg
                      cursor={"pointer"}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      <path
                        d="M14.2189 21.6303C13.0389 21.6303 11.3689 20.8003 10.0489 16.8303L9.32891 14.6703L7.16891 13.9503C3.20891 12.6303 2.37891 10.9603 2.37891 9.78031C2.37891 8.61031 3.20891 6.93032 7.16891 5.60032L15.6589 2.77032C17.7789 2.06032 19.5489 2.27032 20.6389 3.35032C21.7289 4.43032 21.9389 6.21032 21.2289 8.33032L18.3989 16.8203C17.0689 20.8003 15.3989 21.6303 14.2189 21.6303ZM7.63891 7.03032C4.85891 7.96032 3.86891 9.06032 3.86891 9.78031C3.86891 10.5003 4.85891 11.6003 7.63891 12.5203L10.1589 13.3603C10.3789 13.4303 10.5589 13.6103 10.6289 13.8303L11.4689 16.3503C12.3889 19.1303 13.4989 20.1203 14.2189 20.1203C14.9389 20.1203 16.0389 19.1303 16.9689 16.3503L19.7989 7.86032C20.3089 6.32032 20.2189 5.06032 19.5689 4.41032C18.9189 3.76032 17.6589 3.68032 16.1289 4.19032L7.63891 7.03032Z"
                        fill="#E9C237"
                      />
                      <path
                        d="M10.1108 14.4C9.92078 14.4 9.73078 14.33 9.58078 14.18C9.29078 13.89 9.29078 13.41 9.58078 13.12L13.1608 9.53C13.4508 9.24 13.9308 9.24 14.2208 9.53C14.5108 9.82 14.5108 10.3 14.2208 10.59L10.6408 14.18C10.5008 14.33 10.3008 14.4 10.1108 14.4Z"
                        fill="#E9C237"
                      />
                    </svg>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )
      )}
    </div>
  );
};

export default SecondTicketPanel;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import Header from "../../components/Header";
import { handlePageChange, checkString } from "../../services/utils/filters";
import useTransactions, {
  useChangeTransactionStatus,
} from "../../services/apis/transactions";
import FilterDrawer from "../../components/common/FilterDrawer";
import { handleFilterByNumber } from "../../services/utils/filters";
import TransactionsPopup from "./TransactionsPopup";
import { DataGridStyle } from "../../styles";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import { useGlobalSocket } from "../../useGlobalSocket";
import AutoRefresh from "../../components/common/AutoRefresh";
import NewTable from "../../components/common/NewTable";

const Transactions = () => {
  const { id } = useParams();
  const mutation = useChangeTransactionStatus();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [invoiceImage] = useState(null);
  const [transaction] = useState(null);
  const [amount, setAmount] = useState("");
  const [reference_number, setReferenceNumber] = useState("");
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    date_from: null,
    date_to: null,
    orderBy: null,
    sort: null,
    page: 0,
    type: [1, 2, 5],
    status: "",
    id: id,
    phone: null,
    email: null,
  });
  const {
    data: transactionData,
    isFetching: transactionsLoading,
    refetch: refetchTransactions,
  } = useTransactions(queryParameters);

  const dispatch = useDispatch();
  const [toggleListen, setToggleListen] = useState(false);
  const { addEventListener, removeEventListener } = useGlobalSocket();

  useEffect(() => {
    if (toggleListen) {
      refetchTransactions();
      addEventListener("transactions-update", () => {
        refetchTransactions();
      });
      return () => {
        removeEventListener("transactions-update", () => {});
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener, toggleListen]);

  const transactionsColumns = ItemsColumns([
    { field: "id", headerName: "Transaction ID", flex: 2 },
    { field: "phone", headerName: "User Phone", flex: 2 },
    {
      field: "amount",
      headerName: "Transaction amount",
      specialRender: "amount",
      flex: 2,
    },
    {
      field: "reference_number",
      headerName: "Reference Number",
      flex: 2,
    },
    {
      field: "created",
      headerName: "Created At",
      flex: 2,
      date: true,
    },
    { field: "type" },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      flex: 2,
      specialRender: "paymentMethods",
    },
    { field: "status_id", headerName: "Status", kyc: true },
  ]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    clearFields();
  };
  const clearFields = () => {
    setAmount("");
    setReferenceNumber("");
  };

  const handleAccept = async () => {
    try {
      setTransactionStatus("approved");
      if (!amount || !reference_number) {
        dispatch(
          setData({
            openSnack: true,
            message: "Please fill in all required fields",
          })
        );
      } else if (isNaN(amount)) {
        dispatch(
          setData({
            openSnack: true,
            message: "Amount must be a number",
          })
        );
      } else {
        setIsSubmitting(true);
        const payload = {
          id: transaction.id,
          status: 2,
          amount: +amount,
          reference_number: reference_number,
        };
        const response = await mutation.mutateAsync(payload);
        if (response.status === 200) {
          transaction.status = "Accept";
          // if (id) refetchUserTransactions();
          // else
          refetchTransactions();
        }
        clearFields();
        setSuccessDialogOpen(true);
        handleCloseDialog();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReject = async () => {
    try {
      setIsSubmitting(true);
      setTransactionStatus("rejected");
      const payload = {
        id: transaction.id,
        status: 3,
      };
      const response = await mutation.mutateAsync(payload);
      if (response.status === 200) {
        transaction.status = "Reject";
        // if (id) refetchUserTransactions();
        // else
        refetchTransactions();
      }
      clearFields();
      setSuccessDialogOpen(true);
      handleCloseDialog();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearchInput = (e) => {
    const { value } = e.target;
    checkString(value, queryParameters, setQueryParameters);
  };

  const handleTypeFilterCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "type",
      queryParameters,
      setQueryParameters
    );
  };

  const handlePaymentFilterCheckbox = (e) => {
    handleFilterByNumber(
      e.target.value,
      "status",
      queryParameters,
      setQueryParameters
    );
  };

  const handleRowClick = (row) => {
    let type = row.type;
    let paymentMethod = row.paymentMethod;
    let status = row.status_id;
    if (
      (paymentMethod === "bankTransfer" ||
        paymentMethod === "bankDeposit" ||
        paymentMethod === "instaPay") &&
      type === "Deposit" &&
      (status === "Accepted" || status === "Rejected")
    ) {
      navigate(`/transaction-details/${row?.id}`, {
        state: row,
      });
    }
    if (
      type === "Deposit" &&
      status === "Pending" &&
      (paymentMethod === "bankTransfer" ||
        paymentMethod === "bankDeposit" ||
        paymentMethod === "instaPay")
    ) {
      navigate(`/transaction-pending/${row?.id}`, {
        state: row,
      });
    }
    if (
      type === "Withdraw" &&
      (paymentMethod === "bankTransfer" ||
        paymentMethod === "bankDeposit" ||
        paymentMethod === "instaPay")
    ) {
      if (status === "Pending") {
        navigate(`/withdraw-pending/${row?.id}`, {
          state: row,
        });
      } else {
        navigate(`/withdraw-details/${row?.id}`, {
          state: row,
        });
      }
    }
  };
  return (
    <Box>
      <DashboardMainHeader />
      <Header title="Money Transactions" marginL={"20px"} />
      <AutoRefresh
        setToggleListen={setToggleListen}
        toggleListen={toggleListen}
      />
      <Box sx={{ display: "flex", width: "100%", gap: 2, mt: 1, p: 3 }}>
        <div className="flex items-center rounded-xl bg-white p-2 w-[95%] md:w-3/5">
          <IconButton type="button" aria-label="search">
            <img alt="search-normal" src={`../../assets/search-normal.png`} />
          </IconButton>
          <InputBase
            onChange={handleSearchInput}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Email or Phone number....."
          />
          <FilterDrawer
            firstList={[
              { id: 1, name: "Deposit" },
              { id: 2, name: "Withdraw" },
              { id: 5, name: "Refund" },
            ]}
            secondList={[
              { id: 1, name: "Pending" },
              { id: 2, name: "Accept" },
              { id: 3, name: "Rejected" },
            ]}
            firstTitle="Transaction Type"
            secondTitle="Payment Status"
            queryParameters={queryParameters}
            setQueryParameters={setQueryParameters}
            firstKey="type"
            secondKey="status"
            handleFirstCheckBox={handleTypeFilterCheckbox}
            handleSecondCheckBox={handlePaymentFilterCheckbox}
          />
        </div>
      </Box>
      <SecondBox>
        <TransactionsPopup
          openDialog={openDialog}
          handleAccept={handleAccept}
          handleCloseDialog={handleCloseDialog}
          handleReject={handleReject}
          invoiceImage={invoiceImage}
          transaction={transaction}
          amount={amount}
          setAmount={setAmount}
          reference_number={reference_number}
          isSubmitting={isSubmitting}
          setReferenceNumber={setReferenceNumber}
        />

        <Box height="100%" sx={{ ...DataGridStyle, overflowX: "auto" }}>
          <NewTable
            columns={transactionsColumns}
            isLoading={transactionsLoading}
            data={transactionsLoading ? [] : transactionData?.data}
            totalItems={
              id ? transactionData?.totalItems : transactionData?.totalItems
            }
            totalPages={
              id ? transactionData?.totalPages : transactionData?.totalPages
            }
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
            handleRowClick={handleRowClick}
          />
        </Box>
      </SecondBox>
      <Dialog
        open={successDialogOpen}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: {
            width: "585px",
            height: "506px",
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            sx={{
              bgcolor: "#D9F4DD",
              height: "80px",
              width: "80px",
              alignSelf: "center",
              marginRight: "15px",
            }}
          >
            <img src={`../../assets/Vector.png`} alt="vector" />
          </Avatar>
          <Typography
            sx={{ marginTop: "10%", marginBottom: "10%" }}
            variant="h6"
          >
            {transactionStatus === "approved"
              ? "Transaction has been approved successfully."
              : transactionStatus === "rejected"
              ? "Transaction has been rejected successfully."
              : "Transaction status"}
          </Typography>
          <Button
            onClick={() => {
              setSuccessDialogOpen(false);
            }}
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#E9C237",
              "&:hover": { backgroundColor: "#E9C237" },
              height: "46px",
              width: "291px",
              borderRadius: "9px",
            }}
          >
            Money Transactions
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Transactions;
